export type TimeFromNow = { unitOfTime: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds', time: number };

export const SECOND = 1;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;

export function getTimeFromNow(unixTime: number): TimeFromNow {
  if (!unixTime) {
    return;
  }
  const now = new Date().getTime();

  const difference = (unixTime / 1000) - (now / 1000);

  const differenceAbs = Math.abs(difference);

  if (differenceAbs / YEAR > 1) {
    return {
      unitOfTime: 'years',
      time: Math.floor(differenceAbs / YEAR) * (difference >= 0 ? 1 : -1)
    };
  } else if (differenceAbs / MONTH * 1.5 > 1) {
    return {
      unitOfTime: 'months',
      time: Math.floor(differenceAbs / MONTH * 1.5) * (difference >= 0 ? 1 : -1)
    };
  } else if (differenceAbs / DAY > 1) {
    return {
      unitOfTime: 'days',
      time: Math.floor(differenceAbs / DAY) * (difference >= 0 ? 1 : -1)
    };
  } else if (differenceAbs / HOUR > 1) {
    return {
      unitOfTime: 'hours',
      time: Math.floor(differenceAbs / HOUR) * (difference >= 0 ? 1 : -1)
    };
  } else if (differenceAbs / MINUTE > 1) {
    return {
      unitOfTime: 'minutes',
      time: Math.floor(differenceAbs / MINUTE) * (difference >= 0 ? 1 : -1)
    };
  } else {
    return {
      unitOfTime: 'seconds',
      time: Math.floor(differenceAbs) * (difference >= 0 ? 1 : -1)
    };
  }
}
