import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {Field} from '../models/Field';
import {FieldNames} from '../constants/FieldNames';
import {FieldTypes} from '../constants/FieldTypes';

@Component({
  selector: 'pqm-field',
  templateUrl: './field.component.html'
})
export class FieldComponent implements OnInit {
  @Input() field: Field;
  @Input() @HostBinding('class.subfield') isSubField = false;
  @Input() isEditing = true;
  @Output() valueChanged = new EventEmitter<{ field: Field, value: any }>();
  fieldType: string;
  fieldTypes = FieldTypes;

  ngOnInit(): void {
    this.fieldType = this.getFieldType();
  }

  getFieldType(): string {
    if (this.field.typeAheadProvider) {
      if (this.field.typeAheadProvider === 'CUSTOMER') {
        return FieldTypes.CUSTOMER;
      } else {
        return FieldTypes.DROPDOWN;
      }
    }
    switch (this.field.name) {
      case FieldNames.PhoneNo:
      case FieldNames.Email:
      case FieldNames.MobileNo:
      case FieldNames.FaxNo:
      case FieldNames.ShipToCustomerName:
        return FieldTypes.TEXT_COPY;
      case FieldNames.AnticipatedOrderValue:
        return FieldTypes.ANTICIPATED_ORDER_VALUE;
      default:
        return this.field.displayType;
    }
  }

  trackByName(index: number, field: Field): string {
    return field.name;
  }
}
