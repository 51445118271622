import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './components/app.component';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {isDevEnv, isEdgeEnv} from './util/environment-utils';
import {EffectsModule} from '@ngrx/effects';
import {OverviewEffect} from './effects/overview.effect';
import {HttpClientModule} from '@angular/common/http';
import {SectionComponent} from './components/section.component';
import {FieldComponent} from './components/field.component';
import {GlobalCssComponent} from './components/global-css.component';
import {DomSanPipe} from './pipes/dom-san.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularMaterialModule} from './angular-material.module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {FieldLabelComponent} from './components/field-label.component';
import {TextFieldComponent} from './components/fields/text-field.component';
import {TextareaFieldComponent} from './components/fields/textarea-field.component';
import {CustomerFieldComponent} from './components/fields/customer-field.component';
import {LocalStorageModule} from 'angular-2-local-storage';
import {CreateCustomerComponent} from './components/dialog/create-customer.component';
import {PqmLabelPipe} from './pipes/label.pipe';
import {DateFieldComponent} from './components/fields/date-field.component';
import {NumericFieldComponent} from './components/fields/numeric-field.component';
import {NumberInputDirective} from './util/number-input.directive';
import {PqmUncaughtErrorsService} from './services/error/uncaught-errors.service';
import {CustomerDetailsComponent} from './components/dialog/customer-details.component';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {TextCopyFieldComponent} from './components/fields/text-copy-field.component';
import {DropdownFieldComponent} from './components/fields/dropdown-field.component';
import {MultiPickFieldComponent} from './components/fields/multi-pick-field.component';
import {SaveConfirmComponent} from './components/dialog/save-confirm.component';
import {OverviewPageComponent} from './components/pages/overview/overview-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AnticipatedOrderValueFieldComponent} from './components/fields/anticipated-order-value-field.component';
import {CollaborationPageComponent} from './components/pages/collaboration/collaboration-page.component';
import {CommentsEffect} from './effects/comments.effect';
import {DeleteConfirmComponent} from './components/dialog/delete-confirm.component';
import {OverviewSectionContentComponent} from './components/pages/overview/overview-section-content.component';
import {CommentSectionContentComponent} from './components/comments/comment-section-content.component';
import {CommentsComponent} from './components/comments/comments.component';
import {ShareWithUserComponent} from './components/share-with-user/share-with-user.component';
import {ShareWithUserEffect} from './effects/share-with-user.effect';
import {ChipListFieldComponent, ChipTooltipPipe} from './components/fields/chip-list-field.component';
import {InviteFormComponent} from './components/share-with-user/invite-section/invite-form.component';
import {SalesInfoSectionComponent} from './components/share-with-user/sales-info-section/sales-info-section.component';
import {SharedWithComponent} from './components/share-with-user/shared-with/shared-with.component';
import {EditPermissionsDropdownComponent} from './components/share-with-user/edit-permissions-dropdown/edit-permissions-dropdown.component';
import {SharedEffect} from './effects/shared.effect';
import {DocumentsPageComponent} from './components/pages/documents/documents-page.component';
import { AlertDialogComponent } from './components/dialog/alert-dialog/alert-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        OverviewPageComponent,
        SectionComponent,
        OverviewSectionContentComponent,
        CommentSectionContentComponent,
        FieldComponent,
        GlobalCssComponent,
        DomSanPipe,
        FieldLabelComponent,
        TextFieldComponent,
        TextareaFieldComponent,
        CustomerFieldComponent,
        CreateCustomerComponent,
        PqmLabelPipe,
        DateFieldComponent,
        NumericFieldComponent,
        NumberInputDirective,
        CustomerDetailsComponent,
        TextCopyFieldComponent,
        DropdownFieldComponent,
        MultiPickFieldComponent,
        SaveConfirmComponent,
        DeleteConfirmComponent,
        AnticipatedOrderValueFieldComponent,
        CollaborationPageComponent,
        DocumentsPageComponent,
        CommentsComponent,
        ShareWithUserComponent,
        ChipListFieldComponent,
        InviteFormComponent,
        SalesInfoSectionComponent,
        SharedWithComponent,
        EditPermissionsDropdownComponent,
        ChipTooltipPipe,
        AlertDialogComponent,
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([SharedEffect, OverviewEffect, CommentsEffect, ShareWithUserEffect]),
        isDevEnv() || isEdgeEnv() ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
        HttpClientModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
        LocalStorageModule.forRoot({
            prefix: 'pqm',
            storageType: 'sessionStorage'
        }),
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'never', appearance: 'outline' } },
        { provide: ErrorHandler, useClass: PqmUncaughtErrorsService },
        { provide: MatDialogRef, useValue: {}}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIconPacks(fas);
  }
}
