import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from '../../services/message.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Customer} from '../../models/Customer';
import {PqmParams} from '../../constants/PqmParams';

@Component({
  selector: 'pqm-customer-details',
  templateUrl: './customer-details.component.html',
  styles: [
    `
      :host {
        display: block;
        padding: 5px 20px;
      }

      .details-name {
        font-weight: 500;
      }

      .details-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .details-item div:first-child {
        color: #888;
        font-size: smaller;
        font-weight: 500;
      }
    `
  ]
})
@UntilDestroy()
export class CustomerDetailsComponent implements OnInit {
  @Input() id: string;
  @Input() customer: Customer;
  items: string[][] = [];
  isEditing = PqmParams.editing;

  constructor(private messageService: MessageService) {
  }

  ngOnInit(): void {
    // labelKey, value
    this.items = [
      ['location', this.customer.location],
      ['city', this.customer.city],
      ['state', this.customer.state],
      ['country', this.customer.country],
      ['phoneUppercase', this.customer.phone],
      ['mobileUppercase', this.customer.mobile],
      ['emailUppercase', this.customer.email],
    ];
  }

  public goToCustomer(): void {
    this.messageService.postCustomer(this.id);
  }
}
