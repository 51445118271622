<button *ngIf="readonlyMode" class="c-button edit-permissions__button edit-permissions__button-readonly" test-id="PERMISSIONS_BUTTON">
  <span>{{(viewOnly ? 'viewer' : 'editor') | pqmLabel}}</span>
</button>
<button *ngIf="!readonlyMode" class="c-button edit-permissions__button" test-id="EDIT_PERMISSIONS_BUTTON" [matMenuTriggerFor]="menu">
  <span>{{(viewOnly ? 'viewer' : 'editor') | pqmLabel}}</span>
  <fa-icon icon="caret-down" size="sm" class="m-l-base"></fa-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <ul class="c-menu">
    <li class="edit-permissions__menu-item p-h-2 p-v-half" [ngClass]="{'edit-permissions__menu-item--active': viewOnly}"
        test-id="VIEWER_MENU_ITEM" (click)="onPermissionsChange(true)">
      <a>
        <fa-icon [style.visibility]="viewOnly ? 'visible' : 'hidden'" icon="check" size="sm"
                 class="edit-permissions__menu-icon--checked p-r-half"></fa-icon>
        {{'viewer' | pqmLabel}}</a>
    </li>
    <li class="edit-permissions__menu-item m-t-half p-h-2 p-v-half" [ngClass]="{'edit-permissions__menu-item--active': !viewOnly}"
        test-id="EDITOR_MENU_ITEM" (click)="onPermissionsChange(false)">
      <a>
        <fa-icon [style.visibility]="viewOnly ? 'hidden' : 'visible'" icon="check" size="sm"
                 class="edit-permissions__menu-icon--checked p-r-half"></fa-icon>
        {{'editor' | pqmLabel}}</a>
    </li>
    <ng-container *ngIf="hasRemove">
      <li>
        <hr style="margin: .5em 0;">
      </li>
      <li #removeItem class="edit-permissions__menu-item p-h-2 p-v-half" test-id="REMOVE_MENU_ITEM" (click)="onRemove(removeItem)">
        <a>
          <fa-icon icon="trash-alt" size="sm"></fa-icon>
          {{'remove' | pqmLabel}}</a>
      </li>
    </ng-container>
  </ul>
</mat-menu>
