import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {withLatestFrom} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PqmLayout} from '../../../models/Layout';
import {PqmErrorService} from '../../../services/error/error.service';
import {getLayout, getQmConfig, getSaveInProgress, State} from '../../../reducers';
import {SaveService} from '../../../services/save.service';
import {QmConfig} from '../../../models/QmConfig';
import {OverviewActions} from '../../../actions/overview.action';
import {PqmParams} from '../../../constants/PqmParams';
import {OverviewService} from '../../../services/overview.service';

@UntilDestroy()
@Component({
  selector: 'pqm-overview-page',
  templateUrl: './overview-page.component.html'
})
export class OverviewPageComponent implements OnInit {
  saveInProgress$: Observable<boolean>;
  waitingToSave = false;
  form: FormGroup = new FormGroup({});
  layout: PqmLayout;
  messageWarnings: string;
  isEditing = PqmParams.editing;

  constructor(private store: Store<State>, private errorService: PqmErrorService, private saveService: SaveService,
              private overviewService: OverviewService) {
    this.saveInProgress$ = this.store.select(getSaveInProgress);
  }

  ngOnInit(): void {
    this.store.select(getQmConfig).pipe(
      withLatestFrom(this.store.select(getLayout)),
      untilDestroyed(this)
    ).subscribe(([qmConfig, layout]) => {
      // tslint:disable-next-line:triple-equals
      if (qmConfig != undefined) {
        if (qmConfig && layout) {
          this.messageWarnings = '';
          this.layout = this.generateLayout(qmConfig, layout);
          if (qmConfig.messageWarnings !== undefined) {
            this.layout.messageWarnings = qmConfig.messageWarnings;
            for (const message of qmConfig.messageWarnings){
                this.messageWarnings += message +  '\n' ;
            }
          }
        }
    }
    });
  }

  handleSave(): void {
    this.waitingToSave = true;
    this.overviewService.waitForRequestsToBeDone().subscribe(() => {
      const controls = this.form.controls;
      Object.keys(controls).map(x => controls[x].markAllAsTouched());
      if (this.form.valid) {
        void this.saveService.save();
      } else {
        this.waitingToSave = false;
        this.errorService.showSnackbarWarning('errorsOnPage');
      }
    });
  }

  handleCancel(): void {
    this.store.dispatch(OverviewActions.cancelOverview());
  }

  trackByIndex(index: number): number {
    return index;
  }

  private generateLayout(qmConfig: QmConfig, layout: PqmLayout): PqmLayout {
    const layoutCopy = JSON.parse(JSON.stringify(layout));
    layoutCopy.rows.forEach(section => {
      Object.assign(section, qmConfig.sections[section.name]);
      section.rows.forEach(row => {
        row.items.forEach(item => {
          Object.assign(item, qmConfig.fields[item.name]);
        });
      });
     });
    return layoutCopy;
  }
}
