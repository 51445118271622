import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NumberFormatService} from '../services/number-format.service';

@Directive({
  selector: 'input[pqmNumberInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputDirective),
      multi: true
    }
  ]
})
export class NumberInputDirective implements ControlValueAccessor {

  constructor(private numberFormatService: NumberFormatService, private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

  @HostListener('input', ['$event'])
  public onInput($event): void {
    this.onChange(this.numberFormatService.parse($event.target.value));
  }

  public onChange(value: number): void {
  }

  @HostListener('blur')
  public onTouched(): void {
  }

  public writeValue(value: number): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.numberFormatService.format(value, false));
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
