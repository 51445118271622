<ng-container *ngIf="field.visible !== false">
  <pqm-field-label *ngIf="!field.hiddenLabel" [label]="field.description" [mandatory]="field.mandatory"
                   [isEditing]="isEditing"></pqm-field-label>
  <ng-container *ngIf="isEditable(); else viewMode">
    <mat-form-field>
      <mat-chip-list selectable="false" #chipList class="typeahead-chip-list" [formControl]="control">
        <mat-chip *ngFor="let option of control.value" [removable]="true" [matTooltip]="option | chipTooltip"
                  (removed)="removeChip(option)">
          {{option.chipDisplayValue ?? option.description}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input [formControl]="chipControl" [matAutocomplete]="auto" #focusable spellcheck="false"
               (blur)="onBlur()" class="typeahead-chip-list__input"
               [placeholder]="field.placeholder" [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addChip($event)" #chipInput>
      </mat-chip-list>
      <button *ngIf="isLoading" type="button" mat-icon-button matSuffix>
        <fa-icon icon="spinner" [spin]="true"></fa-icon>
      </button>
      <mat-error *ngIf="hasErrors()">{{getErrors()}}</mat-error>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectOption($event)">
        <mat-option *ngIf="!options?.length" [disabled]="true">{{'noAvailableOptions' | pqmLabel}}</mat-option>
        <mat-option *ngFor="let option of options" [value]="option">
          <div class="dropdown-option">
            <div>{{option.description}}</div>
            <div *ngIf="option.email" class="dropdown-option__email">{{option.email}}</div>
            <div *ngIf="option.subInfo" class="dropdown-option__sub-info">{{option.subInfo}}</div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-container>

  <ng-template #viewMode>
    <ng-container>
      <mat-chip-list selectable="false">
        <mat-chip *ngFor="let option of field.value"
                  [matTooltip]="option | chipTooltip">{{option.chipDisplayValue ?? option.description}}</mat-chip>
      </mat-chip-list>
    </ng-container>
  </ng-template>
</ng-container>
