import {Component, OnInit} from '@angular/core';
import {AbstractFieldComponent} from './abstract-field.component';
import {Store} from '@ngrx/store';
import {FormGroupDirective, ValidatorFn} from '@angular/forms';
import {State} from '../../reducers';
import {NumberFormatService} from '../../services/number-format.service';
import {FieldNames} from '../../constants/FieldNames';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pqm-numeric-field',
  templateUrl: './numeric-field.component.html',
  styles: [
    `
      .percent-symbol {
        white-space: nowrap;
        padding-left: calc(0.25em * var(--ui-density));
        padding-top: calc(0.5em * var(--ui-density));
      }
    `
  ]
})
export class NumericFieldComponent extends AbstractFieldComponent implements OnInit {
  displayValue = '';

  constructor(store: Store<State>, formDirective: FormGroupDirective, private numberFormatService: NumberFormatService) {
    super(store, formDirective);
  }

  ngOnInit(): void {
    this.updateDisplayValue();
    if (this.isEditing) {
      this.setupControl();
    }
  }

  // override
  protected updateDisplayValue(): void {
    if (this.isPercentField()) {
      this.displayValue = this.numberFormatService.formatPercent(this.field.value);
    } else if (this.isQuoteApprovalTotal()) {
      this.displayValue = this.numberFormatService.formatQuoteApproval(this.field.value);
    }else{
      this.displayValue = this.numberFormatService.format(this.field.value);
    }
  }

  // override
  protected getValidators(): ValidatorFn[] {
    const validators = super.getValidators();
    validators.push(this.numberFormatService.validator());
    return validators;
  }

  public isPercentField(): boolean {
    return this.field.name === FieldNames.Probability;
  }

  public isQuoteApprovalTotal(): boolean {
    return this.field.name === FieldNames.approvalTotal;
  }
}
