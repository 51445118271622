<ng-container *ngIf="isEditable(); else viewMode">
  <div style="display: flex">
    <mat-form-field *ngIf="control">
      <input type="text" matInput pqmNumberInput [formControl]="control"/>
      <mat-error *ngIf="hasErrors()">{{getErrors()}}</mat-error>
    </mat-form-field>
    <div *ngIf="isPercentField()" class="percent-symbol">%</div>
  </div>
</ng-container>

<ng-template #viewMode>
  <div class="c-overview-details-grid__value">
    {{displayValue}}
  </div>
</ng-template>

