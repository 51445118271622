<div *ngIf="comments" class="main-container">
  <form [formGroup]="form" class="add-comment__form m-b-2" [class.cursor-in-progress]="saveInProgress$ | async">
    <pqm-field class="add-comment__field" attr.test-id="{{newCommentField.name}}" [field]="newCommentField"></pqm-field>
    <div class="m-l-2">
      <button class="c-button add-comment__button" type="button" test-id="ADD_COMMENT_BUTTON"
              (click)="addComment()">{{'comment' | pqmLabel}}</button>
    </div>
  </form>
  <pqm-section *ngFor="let section of commentSections; trackBy: trackByName" [section]="section">
    <div>
      <pqm-comment-section-content [section]="section" [(isEditing)]="isEditingCollection[section.name]"
                                   (delete)="deleteComment($event)"
                                   (save)="editComment($event)"></pqm-comment-section-content>
    </div>
  </pqm-section>
</div>
