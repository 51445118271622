export class UrlUtil {

  static getCurrentUrl(): string {
    return window.location.href;
  }

  static parseUrlQuery(urlVal: string): URLSearchParams {
    return new URL(urlVal).searchParams;
  }

  static getHost(): string {
    return new URL(UrlUtil.getCurrentUrl()).host;
  }

  static createPath(...parts: string[]): string {
    return parts.filter(part => !!part).join('/');
  }
}

