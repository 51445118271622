import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {FormGroup} from '@angular/forms';
import {
  getSalesFieldChangeInProgress, getSalesFieldsResetInProgress, getSalesFieldsResetSuccess, getSalesFieldsSaveInProgress,
  getSalesFieldsSaveSuccess, State
} from '../../../reducers';
import {Field} from '../../../models/Field';
import {selectValue} from '../../../util/rx-utils';
import {merge, Observable} from 'rxjs';
import {PqmErrorService} from '../../../services/error/error.service';

@UntilDestroy()
@Component({
  selector: 'pqm-sales-info',
  templateUrl: './sales-info-section.component.html',
  styles: [
    `
      .sales-fields__container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        grid-column-gap: 1rem;
      }

      .sales-fields__container--editing {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      }
    `
  ]
})
export class SalesInfoSectionComponent implements OnInit {

  @Input() editMode = false;
  @Input() fields: Field[];
  @Output() changeField = new EventEmitter<{ field: Field, value: any }>();
  @Output() save = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<{ hasChanges: boolean }>();

  form: FormGroup = new FormGroup({});
  hasChanges = false;
  isInProgress$: Observable<boolean>;

  constructor(private store: Store<State>, private errorService: PqmErrorService) {
    this.isInProgress$ = merge(this.store.select(getSalesFieldChangeInProgress), this.store.select(getSalesFieldsSaveInProgress),
      this.store.select(getSalesFieldsResetInProgress));
  }

  get saveInProgress(): boolean {
    return selectValue(this.store, getSalesFieldsSaveInProgress);
  }

  get changeInProgress(): boolean {
    return selectValue(this.store, getSalesFieldChangeInProgress);
  }

  get resetInProgress(): boolean {
    return selectValue(this.store, getSalesFieldsResetInProgress);
  }

  ngOnInit(): void {
    merge(this.store.select(getSalesFieldsSaveSuccess), this.store.select(getSalesFieldsResetSuccess)).pipe(
      untilDestroyed(this)
    ).subscribe((success) => {
      if (success) {
        this.editMode = false;
        this.hasChanges = false;
      }
    });
  }

  onEdit(): void {
    this.editMode = true;
    // need to recreate the form as fields will add new controls to the form automatically
    this.form = new FormGroup({});
  }

  onCancel(): void {
    if (this.changeInProgress || this.saveInProgress || this.resetInProgress) {
      return;
    }
    this.cancel.emit({hasChanges: this.hasChanges});
    this.editMode = false;
  }

  onFieldChanged({field, value}): void {
    this.hasChanges = true;
    this.changeField.emit({field, value});
  }

  onSave(): void {
    const controls = this.form.controls;
    Object.keys(controls).map(x => controls[x].markAllAsTouched());
    if (this.form.invalid) {
      this.errorService.showSnackbarWarning('errorsOnPage');
      return;
    }
    if (this.changeInProgress || this.saveInProgress || this.resetInProgress) {
      return;
    }
    this.save.emit();
  }

  trackByName(index: number, item: Field): string {
    return item.name;
  }
}
