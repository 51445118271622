import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'pqm-save-confirm',
  templateUrl: './save-confirm.component.html'
})
export class SaveConfirmComponent {

  constructor(private dialogRef: MatDialogRef<SaveConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data) {
  }

  no(): void {
    this.dialogRef.close();
  }

  yes(): void {
    this.dialogRef.close(true);
  }
}
