import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {fetchSharesInProgress, getDeleteShareInProgress, getEditShareInProgress, getShares, State} from '../../../reducers';
import {Team, User} from '../../../models/UsersAndTeams';
import {ShareWithUsersAndTeamsModel} from '../../../services/collaboration.service';
import {UserService} from '../../../services/user.service';
import {LabelService} from '../../../services/label.service';
import {merge, Observable} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'pqm-shared-with',
  templateUrl: './shared-with.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedWithComponent implements OnInit {

  @Output() edit = new EventEmitter<ShareWithUsersAndTeamsModel>();
  @Output() delete = new EventEmitter<ShareWithUsersAndTeamsModel>();

  users: User[];
  teams: Team[];
  isInProgress$: Observable<boolean>;

  constructor(private store: Store<State>, private userService: UserService, private labelService: LabelService,
              private changeDetector: ChangeDetectorRef) {
    this.isInProgress$ = merge(this.store.select(getEditShareInProgress), this.store.select(getDeleteShareInProgress),
      this.store.select(fetchSharesInProgress));
  }

  ngOnInit(): void {
    this.store.select(getShares).pipe(
      untilDestroyed(this)
    ).subscribe((usersAndTeams) => {
      if (usersAndTeams) {
        this.users = usersAndTeams.users.map((user) => ({
          ...user,
          fullName: this.userService.getFullName(user.firstName, user.lastName),
          translatedRoles: user.roles?.map(this.labelService.translateInstant.bind(this.labelService)).join(', ')
        }));
        this.teams = usersAndTeams.teams;
        this.changeDetector.detectChanges();
      }
    });
  }

  editShare(data: { share: User | Team, type: 'user' | 'team', viewOnly: boolean }): void {
    const model: ShareWithUsersAndTeamsModel = {
      [data.type === 'user' ? 'users' : 'teams']: [{id: data.share.id, viewOnly: data.viewOnly}]
    };
    this.edit.emit(model);
  }

  deleteShare(data: { share: User | Team, type: 'user' | 'team' }): void {
    const model: ShareWithUsersAndTeamsModel = {
      [data.type === 'user' ? 'users' : 'teams']: [{id: data.share.id}]
    };
    this.delete.emit(model);
  }

  trackById(index: number, item: User | Team): number {
    return item.id;
  }

  isActionMenuVisible(share: User | Team, type: 'user' | 'team'): boolean {
    if (type === 'user') {
      return !this.isSalesUser(share as User);
    } else {
      return true;
    }
  }

  isSalesUser(user: User): boolean {
    return !!user.roles?.length;
  }
}
