import {Component, OnInit} from '@angular/core';
import {AbstractFieldComponent} from './abstract-field.component';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Component({
  selector: 'pqm-multi-pick-field',
  templateUrl: './multi-pick-field.component.html'
})
export class MultiPickFieldComponent extends AbstractFieldComponent implements OnInit {
  displayValue: any;

  ngOnInit(): void {
    this.updateDisplayValue();
    if (this.isEditing) {
      this.setupControl();
    }
  }

  // override
  protected convertValueForServer(value: any): any {
    if (value.length > 0) {
      return value.join(',');
    } else {
      return null;
    }
  }

  // override
  protected getValidators(): ValidatorFn[] {
    return [
      (control: AbstractControl): { [key: string]: any } | null => {
        if (this.field.mandatory && (!control.value || control.value.length === 0)) {
          return {required: true};
        }
        return null;
      }
    ];
  }

  // override
  protected updateDisplayValue(): void {
    if (this.field.value) {
      this.displayValue = this.field.value.map(value => {
        const matchedOption = this.field.optionList.find(option => option.persistenceId === value);
        return matchedOption.description;
      }).join(', ');
    } else {
      this.displayValue = '';
    }
  }
}
