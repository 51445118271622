import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getLabels, State} from '../reducers';
import {Observable} from 'rxjs';
import {Labels} from '../models/Labels';
import {map} from 'rxjs/operators';
import {selectValue} from '../util/rx-utils';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  private labels$: Observable<Labels>;

  constructor(private store: Store<State>) {
    this.labels$ = this.store.select(getLabels);
  }

  get(key: string): Observable<string> {
    return this.labels$.pipe(map(labels => {
      return labels.hasOwnProperty(key) ? labels[key] : '';
    }));
  }

  translateInstant(key: string): string {
    const labels = selectValue(this.store, getLabels);
    return labels.hasOwnProperty(key) ? labels[key] : '';
  }
}
