import {Injectable} from '@angular/core';
import {ExpandedState} from '../models/ExpandedState';
import {Section} from '../models/Section';
import {StorageKeys} from '../constants/StorageKeys';
import {PqmParams} from '../constants/PqmParams';

@Injectable({
  providedIn: 'root'
})
export class ExpandedService {
  private expandedState: ExpandedState;

  public initializeState(): void {
    const expandedState: ExpandedState = this.retrieveExpandedState();
    if (!expandedState || expandedState.itemContainer !== PqmParams.itemContainerId) {
      this.storeExpandedState({
        itemContainer: PqmParams.itemContainerId,
        sections: {}
      });
    }
  }

  public toggleExpandedState(section: Section, expanded: boolean): void {
    if (section.name) {
      const expandedState: ExpandedState = this.retrieveExpandedState();
      expandedState.sections[section.name] = expanded;
      this.storeExpandedState(expandedState);
    }
  }

  public getExpandedState(section: Section): boolean {
    if (section.name) {
      const expandedState: ExpandedState = this.retrieveExpandedState();
      if (expandedState) {
        return expandedState.sections[section.name] ?? this.getDefaultState(section);
      }
    }
    return this.getDefaultState(section);
  }

  private getDefaultState(section: Section): boolean {
    return section.expanded ?? true;
  }

  private storeExpandedState(expandedState: ExpandedState): void {
    this.expandedState = expandedState;
    sessionStorage.setItem(StorageKeys.EXPANDED, JSON.stringify(expandedState));
  }

  private retrieveExpandedState(): ExpandedState {
    if (!this.expandedState) {
      this.expandedState = JSON.parse(sessionStorage.getItem(StorageKeys.EXPANDED));
    }
    return this.expandedState;
  }
}
