import {first} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Selector} from '@ngrx/store/src/models';

/**
 * Only use this when the observable / subscription will be disposed of immediately after use.
 * Be careful... the 'first truthy' value may not yet be initialized properly, but still truthy.
 */
export function selectFirstTruthy<State, Props, Result>(store: Store<State>, selectFn: Selector<State, Result>): Observable<Result> {
  return store.pipe(select(selectFn), first(a => !!a));
}

export function selectValue<State, Result>(store: Store<State>, selectFn: Selector<State, Result>): Result {
  let value;
  store.select(selectFn).pipe(first()).subscribe(v => value = v);
  return value;
}
