<div *ngIf="waitingToSave" id="waitingToSave" [ngClass]="waitingToSave ? 'showWaiting':'' "  style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 10001; background: white;">
  <div style="padding: 50px; display: flex; align-items: center">
    <mat-progress-spinner mode="indeterminate" [diameter]="50"></mat-progress-spinner>
    <div style="margin-left: 40px; font-size: 20px" test-id="SAVE_IN_PROGRESS_MESSAGE">{{'savingMessage' | pqmLabel}}</div>
  </div>
</div>

<div *ngIf="layout; else initializing" class="main-container">
  <div *ngIf="isEditing" class="sticky-content flex-grid flex-shrink m-b-half">
    <button class="c-button" test-id="SAVE_BUTTON" (click)="handleSave()">
      <fa-icon icon="save" class="m-r-base"></fa-icon>
      <span>{{'save' | pqmLabel}}</span>
    </button>
    <button type="button" class="c-button c-button--grey-light" test-id="CANCEL_BUTTON"
            (click)="handleCancel()">{{'cancel' | pqmLabel}}</button>
  </div>
  <div style="color:red;white-space: pre-line" [innerHTML]="messageWarnings"></div>
  <form [formGroup]="form">
    <pqm-section *ngFor="let section of layout.rows; trackBy: trackByIndex" [section]="section">
      <div>
        <pqm-overview-section-content [section]="section"></pqm-overview-section-content>
      </div>
    </pqm-section>
  </form>
</div>

<ng-template #initializing>
  <mat-progress-spinner mode="indeterminate" [diameter]="50" style="margin: 20px auto"></mat-progress-spinner>
</ng-template>
