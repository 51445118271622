import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {PqmParams} from '../constants/PqmParams';

@Component({
  selector: 'pqm-global-css',
  template: `
    <link *ngFor="let link of links" rel="stylesheet" [href]="link | domSan: 'resourceUrl'" (load)="onLoad()">
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalCssComponent implements OnInit {
  links: string[];
  numLoaded = 0;
  @Output() allLoaded = new EventEmitter<void>();

  ngOnInit(): void {
    this.links = [
      `${PqmParams.cssPath}/ife-foxhound.css`,
      `${PqmParams.cssPath}/ife-material.css`
    ];
  }

  onLoad(): void {
    this.numLoaded++;
    if (this.numLoaded === this.links.length) {
      this.allLoaded.emit();
    }
  }
}
