import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Overview} from '../models/Overview';
import {Field} from '../models/Field';
import {ApiService} from './api.service';
import {first, tap} from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  private changedFields = new Set<string>();
  private inProgress = new BehaviorSubject(0);

  constructor(private apiService: ApiService) {
  }

  submitFieldChange(field: Field, newValue: any): Observable<Overview> {
    this.inProgress.next(this.inProgress.value + 1);
    this.changedFields.add(field.name);
    return this.apiService.submitFieldChange({
      name: field.name,
      displayType: field.displayType,
      value: field.productLineId ? field.productLineId + ',' + newValue : newValue
    }).pipe(tap(() => {
      this.inProgress.next(this.inProgress.value - 1);
    }));
  }

  fillShiftToFields(): Observable<Overview> {
    return this.apiService.fillShiftToFields();
  }

  isFieldChanged(field: string): boolean {
    return this.changedFields.has(field);
  }

  isRequestInProgress(): boolean {
    return this.inProgress.value > 0;
  }

  waitForRequestsToBeDone(): Observable<number> {
    return this.inProgress.pipe(first(val => val === 0));
  }
}
