import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormGroupDirective, ValidatorFn} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Option} from '../../models/Option';
import {debounceTime, filter, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {getQmConfig, State} from '../../reducers';
import {MatDialog} from '@angular/material/dialog';
import {CreateCustomerComponent} from '../dialog/create-customer.component';
import {untilDestroyed} from '@ngneat/until-destroy';
import {AbstractFieldComponent} from './abstract-field.component';
import {TypeaheadProviders} from '../../constants/TypeaheadProviders';
import {ApiService} from '../../services/api.service';
import {Customer} from '../../models/Customer';
import {CustomerField} from '../../models/Field';

@Component({
  selector: 'pqm-customer-field',
  templateUrl: './customer-field.component.html',
  styles: [
    `
      .mat-menu-trigger {
        cursor: pointer;
      }
    `
  ]
})
export class CustomerFieldComponent extends AbstractFieldComponent implements OnInit {

  private static DEBOUNCE_TIME = 500;

  @Input() field: CustomerField;
  @ViewChild('typeAheadField') inputElement: ElementRef;
  filteredOptions: Customer[];
  backUpOption: Customer;
  searchTerm$ = new Subject<any>();
  quickSelect: Customer[] = [];

  constructor(store: Store<State>, private apiService: ApiService, private dialog: MatDialog,
              formDirective: FormGroupDirective) {
    super(store, formDirective);
  }

  ngOnInit(): void {
    if (this.isEditing) {
      this.setupControl({updateOn: 'change'});
      this.backUpOption = this.field.selectedOption as Customer;
      this.searchTerm$.pipe(
        untilDestroyed(this),
        debounceTime(CustomerFieldComponent.DEBOUNCE_TIME),
        filter(value => typeof value === 'string'),
        switchMap((value: any) => {
          return !!value ? this.apiService.searchForCustomer({
            query: value,
            type: this.field.typeAheadType
          }) : of([]);
        })
      ).subscribe(data => {
        this.filteredOptions = data;
      });
      this.initQuickSelect();
    }
  }

  // override
  protected getFieldValue(): any {
    return this.field.selectedOption;
  }

  // override
  protected getValidators(): ValidatorFn[] {
    return [this.typeaheadSelectionValidator()];
  }

  // override
  protected onChange(value): void {
    this.searchTerm$.next(value);
  }

  showQuickSelect(): boolean {
    return !this.filteredOptions?.length;
  }

  valueSelected($event: MatAutocompleteSelectedEvent): void {
    const choice = $event.option.value;
    if (this.field.selectedOption?.persistenceId === choice?.persistenceId) {
      // user selected the same value, so don't submit
    } else {
      this.persistChange(choice.persistenceId);
    }
    this.inputElement.nativeElement.blur();
  }

  persistChange(persistenceId: number): void {
    this.valueChanged.emit({field: this.field, value: String(persistenceId)});
  }

  onBlur(): void {
    if (this.control.value === '') {
      this.persistChange(0);
    }
  }

  displayFn(val: Option): string {
    return val ? val.description : '';
  }

  resetDefault(): void {
    this.control.setValue(this.backUpOption);
  }

  createNewOption(): void {
    const inputPosition = this.inputElement.nativeElement.getBoundingClientRect();
    const dialogRef = this.dialog.open(CreateCustomerComponent, {
      disableClose: true,
      data: {
        type: this.field.typeAheadType
      },
      position: {
        top: (inputPosition.top - 150) + 'px'
      },
      id: 'DIALOG_CREATE_CUSTOMER'
    });

    dialogRef.afterClosed().subscribe(choice => {
      if (!!choice) {
        this.control.setValue(choice);
        this.persistChange(choice.persistenceId);
      }
    });
  }

  private typeaheadSelectionValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selection: any = control.value;
      if (this.field.mandatory && (!selection || selection.persistenceId === '0')) {
        return {required: true};
      } else if (selection && typeof selection === 'string') {
        return {selectionRequired: true};
      }
      return null;
    };
  }

  private initQuickSelect(): void {
    // for all customer fields, it gives the user initial options without having to type anything
    // the initial options come from the selected options in other customer fields
    this.store.select(getQmConfig).pipe(untilDestroyed(this)).subscribe(qmConfig => {
      this.quickSelect = [];
      for (const key of Object.keys(qmConfig.fields)) {
        const field = qmConfig.fields[key];
        if (field.typeAheadProvider === TypeaheadProviders.CUSTOMER) {
          if (field.selectedOption?.persistenceId !== '0') {
            if (!this.quickSelect.find(item => item.persistenceId === field.selectedOption.persistenceId)) {
              this.quickSelect.push(field.selectedOption as Customer);
            }
          }
        }
      }
    });
  }
}
