import {Component, Input} from '@angular/core';
import {PqmParams} from '../../../constants/PqmParams';
import {Field} from '../../../models/Field';
import {PqmLayoutRow} from '../../../models/Layout';
import {OverviewSection} from '../../../models/OverviewSection';
import {Store} from '@ngrx/store';
import {State} from '../../../reducers';
import {OverviewActions} from '../../../actions/overview.action';
import {OverviewService} from '../../../services/overview.service';
import {PqmErrorService} from '../../../services/error/error.service';

@Component({
  selector: 'pqm-overview-section-content',
  templateUrl: './overview-section-content.component.html'
})
export class OverviewSectionContentComponent {
  pqmParams = PqmParams;

  @Input() section: OverviewSection;

  constructor(private store: Store<State>, private overviewService: OverviewService, private errorService: PqmErrorService) {
  }

  trackByIndex(index: number): number {
    return index;
  }

  trackByName(index: number, field: Field): string {
    return field.name;
  }

  isRowVisible(row: PqmLayoutRow): boolean {
    return row.items.some(field => field.type === 'field' && field.visible !== false);
  }

  onFieldChanged({field, value}): void {
    this.overviewService.submitFieldChange(field, value).subscribe(res => {
      // only refresh if this is the last request in the queue, otherwise we run into race conditions
      if (!this.overviewService.isRequestInProgress()) {
        this.store.dispatch(OverviewActions.fetchOverviewSuccess({response: res}));
      }
    }, error => {
      this.errorService.apiError('Failed to submit field change', error, {field, value}, 'Failed to submit change');
      this.store.dispatch(OverviewActions.changeFieldFailed());
    });
  }
}
