import {Component, Input, OnInit} from '@angular/core';
import {getComments, getLabels, getUserEditPermission, getIsOldRevision, State} from '../../../reducers';
import {Store} from '@ngrx/store';
import {combineLatest} from 'rxjs';
import {CommentsActions} from '../../../actions/comments.action';
import {selectFirstTruthy} from '../../../util/rx-utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ShareWithUserActions} from '../../../actions/share-with-user.action';

@UntilDestroy()
@Component({
  selector: 'pqm-collaboration-page',
  templateUrl: './collaboration-page.component.html',
  styles: [
    `
      .comments__restricted-width {
        display: block;
        max-width: 800px;
      }
    `
  ]
})
export class CollaborationPageComponent implements OnInit {
  isInitialized = false;
  editPermission: boolean;
  isOldRevision: boolean;

  @Input() itemContainerId: number;

  constructor(private store: Store<State>) {
    combineLatest([
      selectFirstTruthy(store, getLabels),
      selectFirstTruthy(store, getComments)
    ]).pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.isInitialized = true;
    });

    this.store.select(getUserEditPermission).pipe(
      untilDestroyed(this)
    ).subscribe((editPermission) => {
      if (typeof editPermission === 'boolean') {
        this.editPermission = editPermission;
      }
    });

    this.store.select(getIsOldRevision).pipe(
      untilDestroyed(this)
    ).subscribe((isOldRevision) => {
      if (typeof isOldRevision === 'boolean') {
        this.isOldRevision = isOldRevision;
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(CommentsActions.fetchComments({itemContainerId: this.itemContainerId}));
    this.store.dispatch(ShareWithUserActions.getShares({itemContainerId: this.itemContainerId}));
  }

  public isEditSectionAvailable(): boolean {
    return this.editPermission && !this.isOldRevision;
  }
}
