import {Component, ElementRef, Input, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {AbstractFieldComponent} from './abstract-field.component';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {ChipListOption} from '../../models/Option';
import {ChipListField} from '../../models/Field';

@UntilDestroy()
@Component({
  selector: 'pqm-chip-list-field',
  templateUrl: './chip-list-field.component.html'
})
export class ChipListFieldComponent extends AbstractFieldComponent implements OnInit {

  private static DEBOUNCE_TIME = 300;

  @Input() field: ChipListField;
  @ViewChild('chipInput') public chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('focusable') public focusableEl: ElementRef;
  chipControl = new FormControl();
  separatorKeysCodes: number[] = [
    ENTER,
    COMMA
  ];
  options: ChipListOption[];
  preventOptionDuplication: boolean; // If true then not possible to select one option some times
  isLoading = false;
  private selectedValue: any;

  ngOnInit(): void {
    if (!this.isEditing) {
      return;
    }
    this.setupControl();
    this.preventOptionDuplication = true;
    if (!this.control.value) {
      this.control.setValue([]);
    }

    this.chipControl.valueChanges.pipe(untilDestroyed(this), startWith<string | ChipListOption>(''),
      debounceTime(ChipListFieldComponent.DEBOUNCE_TIME),
      map((value: string) => {
        this.isLoading = true;
        this.selectedValue = null;
        return this.searchForOptions(value);
      }))
      .subscribe((options: ChipListOption[]) => {
        this.options = options;
        this.isLoading = false;
      });
  }

  searchForOptions(value?: string): ChipListOption[] {
    let availableOptions = this.field.optionList;
    if (this.preventOptionDuplication) {
      availableOptions = this.field.optionList.filter((testOption) => {
        return !this.isOptionSelected(testOption);
      });
    }
    if (!value) {
      return availableOptions;
    }
    const lowerCaseValue = value.toLowerCase();
    return availableOptions.filter((testOption: ChipListOption) => {
      return testOption.description.toLowerCase().includes(lowerCaseValue);
    });
  }

  /** Run this when field.optionList changed. */
  onOptionsUpdated(): void {
    this.options = this.searchForOptions();
  }

  addChip(event: MatChipInputEvent): void {
    const value = event.value;

    if ((value || '').trim()) {
      const options = this.searchForOptions(value);
      if (options.length === 1) {
        this.addOption(options[0]);
      } else {
        const lowercaseValue = value.toLowerCase();

        const foundRecord = options.find((testOption: ChipListOption) => {
          return testOption.description.toLowerCase().startsWith(lowercaseValue);
        });

        if (foundRecord) {
          this.addOption(foundRecord);
        }
      }
    }
    this.clearChipListInput();
  }

  removeChip(option: ChipListOption): void {
    this.removeOption(option);
  }

  onBlur(): void {
    this.clearChipListInput();
  }

  isOptionSelected(option: ChipListOption): boolean {
    if (!option) {
      return;
    }
    return this.control.value?.find((value: ChipListOption) => option.persistenceId === value.persistenceId);
  }

  onSelectOption(event: MatAutocompleteSelectedEvent): void {
    this.addOption(event.option.value);
    this.clearChipListInput();
  }

  private addOption(option: ChipListOption): void {
    const controlVal: ChipListOption[] = this.control.value;
    controlVal.push(option);
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.setValue(controlVal);
  }

  private removeOption(option: ChipListOption): void {
    const controlVal: ChipListOption[] = this.control.value;
    const index = controlVal.findIndex((value) => option.persistenceId === value.persistenceId);
    if (index >= 0) {
      controlVal.splice(index, 1);
    }
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.setValue(controlVal);
  }

  private clearChipListInput(): void {
    this.chipInput.nativeElement.value = '';
    this.chipControl.setValue('');
  }
}

@Pipe({name: 'chipTooltip'})
export class ChipTooltipPipe implements PipeTransform {

  transform(option: ChipListOption): string {
    if (!option.chipDisplayValue && !option.email && !option.subInfo) {
      return; // the chip contains the whole information, don't need to show the same value in the tooltip
    }
    if (option.email) {
      return `${option.description} (${option.email})`;
    }
    return option.subInfo ? `${option.description} (${option.subInfo})` : option.description;
  }
}
