<ng-container *ngIf="isEditable(); else viewMode">
  <mat-form-field>
    <mat-select [formControl]="control" [disableOptionCentering]="true" [multiple]="true">
      <mat-option *ngFor="let option of field.optionList" [value]="option.persistenceId">
        {{option.description}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="hasErrors()">{{getErrors()}}</mat-error>
  </mat-form-field>
</ng-container>

<ng-template #viewMode>
  <div class="c-overview-details-grid__value">
    {{displayValue}}
  </div>
</ng-template>


