<ng-container *ngIf="isEditable(); else viewMode">
  <mat-form-field>
    <div class="c-input-group m-b-none">
      <input type="text" #textInput matInput [formControl]="control"/>
      <button *ngIf="valueToCopy" class="c-button c-button--secondary" type="button" title="{{copyButtonLabel | pqmLabel}}"
              test-id="COPY_BUTTON" (click)="onCopy($event)">
        <fa-icon icon="file-import"></fa-icon>
      </button>
    </div>
    <mat-error *ngIf="hasErrors()">{{getErrors()}}</mat-error>
  </mat-form-field>
</ng-container>

<ng-template #viewMode>
  <div class="c-overview-details-grid__value">
    {{field.value}}
  </div>
</ng-template>

