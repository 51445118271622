import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  getFullName(userFirstName?: string, userLastName?: string, userEmail?: string): string {
    let result = '';
    if (userLastName) {
      result += userLastName;
    }
    if (userFirstName && result) {
      result += `, ${userFirstName}`;
    }
    if (userFirstName && !result) {
      result += userFirstName;
    }
    if (userEmail && result) {
      result += ` (${userEmail})`;
    }
    if (userEmail && !result) {
      result += userEmail;
    }
    return result;
  }
}
