<ng-container *ngIf="(salesFields$ | async) as salesFields">
  <div *ngIf="salesFields.length" class="c-details-sharing share-with__sales-section">
    <pqm-section [section]="salesInfoSection">
      <pqm-sales-info [fields]="salesFields" (save)="saveSalesFields()" (changeField)="changeSalesField($event)"
                      (cancel)="resetSalesFields($event)"></pqm-sales-info>
    </pqm-section>
  </div>
</ng-container>
<div class="c-details-sharing share-with__invite-section">
  <pqm-section [section]="inviteSection">
    <pqm-invite-form (share)="share($event)"></pqm-invite-form>
  </pqm-section>
</div>
<div class="c-details-sharing p-h-2 share-with__shared-with">
  <pqm-shared-with (edit)="edit($event)" (delete)="delete($event)"></pqm-shared-with>
</div>
