<mat-dialog-content>
  <div test-id="CONFIRM_MESSAGE">{{data.message | pqmLabel}}</div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="flex-grid flex-shrink m-b-half">
    <button type="button" class="c-button" test-id="YES_BUTTON" (click)="yes()">{{'yes' | pqmLabel}}</button>
    <button type="button" class="c-button c-button--grey-light" test-id="NO_BUTTON" (click)="no()">{{'no' | pqmLabel}}</button>
  </div>
</mat-dialog-actions>
