import {
  createAction,
  props
} from '@ngrx/store';
import {Comment} from '../models/Comment';

const prefix = '[comments] ';

export namespace CommentsActions {
  export const fetchComments = createAction(prefix + 'Fetch comments', props<{ itemContainerId: number }>());

  export const fetchCommentsSuccess = createAction(prefix + 'Fetch comments success', props<{ response: Comment[] }>());

  export const fetchCommentsFailed = createAction(prefix + 'Fetch comments failed');

  export const saveComment = createAction(prefix + 'Save comment', props<{ itemContainerId: number, comment: string }>());

  export const saveCommentSuccess = createAction(prefix + 'Save comment success',
    props<{ itemContainerId: number }>());

  export const saveCommentFailed = createAction(prefix + 'Save comment failed');

  export const deleteComment = createAction(prefix + 'Delete comment', props<{ itemContainerId: number, id: number }>());

  export const deleteCommentSuccess = createAction(prefix + 'Delete comment success',
    props<{ itemContainerId: number }>());

  export const deleteCommentFailed = createAction(prefix + 'Delete comment failed');

  export const editComment = createAction(prefix + 'Edit comment', props<{ itemContainerId: number, id: number, comment: string }>());

  export const editCommentSuccess = createAction(prefix + 'Edit comment success',
    props<{ itemContainerId: number, id: number }>());

  export const editCommentFailed = createAction(prefix + 'Edit comment failed');

  export const resetState = createAction(prefix + 'Reset comments state');
}
