import {createReducer, on} from '@ngrx/store';
import {UsersAndTeams} from '../models/UsersAndTeams';
import {ShareWithUserActions} from '../actions/share-with-user.action';

export interface ShareWithUserState {
  salesFieldChangeInProgress?: boolean;
  salesFieldChangeSuccess?: string | boolean; // changed field name or false
  salesFieldsSaveInProgress?: boolean;
  salesFieldsSaveSuccess?: boolean;
  salesFieldsResetInProgress?: boolean;
  salesFieldsResetSuccess?: boolean;
  usersAndTeams: UsersAndTeams;
  shareInProgress?: boolean;
  shareSuccess?: boolean;
  getSharesInProgress?: boolean;
  shares: UsersAndTeams;
  editShareInProgress?: boolean;
  editShareSuccess?: boolean;
  deleteShareInProgress?: boolean;
  deleteShareSuccess?: boolean;
}

const initialState: ShareWithUserState = {
  usersAndTeams: null,
  shares: null,
};

export const shareWithUserReducer = createReducer(
  initialState,
  on(ShareWithUserActions.changeSalesField, (state) => ({
    ...state,
    salesFieldChangeInProgress: true,
    salesFieldChangeSuccess: null,
  })),
  on(ShareWithUserActions.changeSalesFieldSuccess, (state, {field}) => ({
    ...state,
    salesFieldChangeInProgress: false,
    salesFieldChangeSuccess: field.name,
  })),
  on(ShareWithUserActions.changeSalesFieldFailed, (state) => ({
    ...state,
    salesFieldChangeInProgress: false,
    salesFieldChangeSuccess: false,
  })),
  on(ShareWithUserActions.saveSalesFields, (state) => ({
    ...state,
    salesFieldsSaveInProgress: true,
    salesFieldsSaveSuccess: null
  })),
  on(ShareWithUserActions.saveSalesFieldsSuccess, (state) => ({
    ...state,
    salesFieldsSaveInProgress: false,
    salesFieldsSaveSuccess: true
  })),
  on(ShareWithUserActions.saveSalesFieldsFailed, (state) => ({
    ...state,
    salesFieldsSaveInProgress: false,
    salesFieldsSaveSuccess: false
  })),
  on(ShareWithUserActions.resetSalesFields, (state) => ({
    ...state,
    salesFieldsResetInProgress: true,
    salesFieldsResetSuccess: null
  })),
  on(ShareWithUserActions.resetSalesFieldsSuccess, (state) => ({
    ...state,
    salesFieldsResetInProgress: false,
    salesFieldsResetSuccess: true
  })),
  on(ShareWithUserActions.resetSalesFieldsFailed, (state) => ({
    ...state,
    salesFieldsResetInProgress: false,
    salesFieldsResetSuccess: false
  })),
  on(ShareWithUserActions.getUsersAndTeamsSuccess, (state, {response}) => ({
    ...state,
    usersAndTeams: response
  })),
  on(ShareWithUserActions.share, (state) => {
    return {...state, shareInProgress: true, shareSuccess: null};
  }),
  on(ShareWithUserActions.shareSuccess, (state) => ({...state, shareInProgress: false, shareSuccess: true})),
  on(ShareWithUserActions.shareFailed, (state) => ({...state, shareInProgress: false, shareSuccess: false})),
  on(ShareWithUserActions.getShares, (state) => {
    return {...state, getSharesInProgress: true};
  }),
  on(ShareWithUserActions.getSharesSuccess, (state, {response}) => ({
    ...state,
    shares: response,
    getSharesInProgress: false
  })),
  on(ShareWithUserActions.getSharesFailed, (state) => ({
    ...state,
    getSharesInProgress: false
  })),
  on(ShareWithUserActions.editShare, (state) => {
    return {...state, editShareInProgress: true, editShareSuccess: null};
  }),
  on(ShareWithUserActions.editShareSuccess, (state) => ({...state, editShareInProgress: false, editShareSuccess: true})),
  on(ShareWithUserActions.editShareFailed, (state) => ({...state, editShareInProgress: false, editShareSuccess: false})),
  on(ShareWithUserActions.deleteShare, (state) => {
    return {...state, deleteShareInProgress: true, deleteShareSuccess: null};
  }),
  on(ShareWithUserActions.deleteShareSuccess, (state) => ({...state, deleteShareInProgress: false, deleteShareSuccess: true})),
  on(ShareWithUserActions.deleteShareFailed, (state) => ({...state, deleteShareInProgress: false, deleteShareSuccess: false})),
  on(ShareWithUserActions.resetState, () => initialState),
);

export class ShareWithUserReducers {
  static getSalesFieldChangeInProgress = (state: ShareWithUserState) => state.salesFieldChangeInProgress;
  static getSalesFieldChangeSuccess = (state: ShareWithUserState) => state.salesFieldChangeSuccess;
  static getSalesFieldsSaveInProgress = (state: ShareWithUserState) => state.salesFieldsSaveInProgress;
  static getSalesFieldsSaveSuccess = (state: ShareWithUserState) => state.salesFieldsSaveSuccess;
  static getSalesFieldsResetInProgress = (state: ShareWithUserState) => state.salesFieldsResetInProgress;
  static getSalesFieldsResetSuccess = (state: ShareWithUserState) => state.salesFieldsResetSuccess;
  static getUsersAndTeams = (state: ShareWithUserState) => state.usersAndTeams;
  static getShareInProgress = (state: ShareWithUserState) => state.shareInProgress;
  static getShareSuccess = (state: ShareWithUserState) => state.shareSuccess;
  static fetchSharesInProgress = (state: ShareWithUserState) => state.getSharesInProgress;
  static getEditShareInProgress = (state: ShareWithUserState) => state.editShareInProgress;
  static getEditShareSuccess = (state: ShareWithUserState) => state.editShareSuccess;
  static getDeleteShareInProgress = (state: ShareWithUserState) => state.deleteShareInProgress;
  static getDeleteShareSuccess = (state: ShareWithUserState) => state.deleteShareSuccess;
  static getShares = (state: ShareWithUserState) => state.shares;
}
