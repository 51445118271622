import {Component, OnInit} from '@angular/core';
import {getUserInfo, isInitFailure, State} from '../reducers';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MessageService} from '../services/message.service';
import {selectFirstTruthy} from '../util/rx-utils';
import {map} from 'rxjs/operators';
import {PageNames} from '../constants/PageNames';
import {PqmParams} from '../constants/PqmParams';
import {SharedActions} from '../actions/shared.action';

@UntilDestroy()
@Component({
  selector: 'pqm-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  initError$: Observable<boolean>;
  displayDensity$: Observable<string>;
  cssLoaded = false;
  page: PageNames;
  itemContainerId: number;

  constructor(private store: Store<State>, private messageService: MessageService) {
    this.initError$ = this.store.select(isInitFailure);
    this.displayDensity$ = selectFirstTruthy(store, getUserInfo).pipe(map(userInfo => userInfo.displayDensity));
  }

  ngOnInit(): void {
    PqmParams.initFromUrl();
    this.store.dispatch(SharedActions.initialize());
    this.itemContainerId = PqmParams.itemContainerId;
    if (PqmParams.editing) {
      this.page = PageNames.OVERVIEW;
    } else {
      this.messageService.pageChange.subscribe(page => this.page = page);
      this.messageService.requestPage();
    }
    this.messageService.refreshNeeded.subscribe(() => this.store.dispatch(SharedActions.refresh()));
  }

  allCssLoaded(): void {
    this.cssLoaded = true;
  }
}
