import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PqmErrorService} from '../../../services/error/error.service';
import {State} from '../../../reducers';

@UntilDestroy()
@Component({
  selector: 'pqm-documents-page',
  templateUrl: './documents-page.component.html'
})
export class DocumentsPageComponent implements OnInit {
  saveInProgress$: Observable<boolean>;

  constructor(private store: Store<State>, private errorService: PqmErrorService) {
  }

  ngOnInit(): void {
      console.log('testing');
  }

}
