import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getCurrency, getUserInfo, State} from '../reducers';
import {selectFirstTruthy, selectValue} from '../util/rx-utils';
import {UserInfo} from '../models/UserInfo';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class NumberFormatService {
  userInfo: UserInfo;

  constructor(private store: Store<State>) {
    selectFirstTruthy(store, getUserInfo).subscribe(userInfo => this.userInfo = userInfo);
  }

  public format(value: number, showGroupingSymbol = true): string {
    if (value === null || value === undefined) {
      return '';
    }
    const groupingSymbol = showGroupingSymbol ? this.userInfo.groupingSymbol : '';
    return Intl.NumberFormat('en-US', {maximumSignificantDigits: 20})
      .format(value)
      .replace(/[\.,]/g, char => {
        return char === '.' ? this.userInfo.decimalSymbol : groupingSymbol;
      });
  }

  public formatCurrency(value: number, showGroupingSymbol = true): string {
    if (value === null || value === undefined) {
      return '';
    }
    const currencySymbol = selectValue(this.store, getCurrency)?.display;
    const groupingSymbol = showGroupingSymbol ? this.userInfo.groupingSymbol : '';
    return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
      .format(value)
      .replace(/[\.,]/g, char => {
        return char === '.' ? this.userInfo.decimalSymbol : groupingSymbol;
      })
      .replace('$', currencySymbol + ' ');
  }

  public formatQuoteApproval(value: number, showGroupingSymbol = true): string {
    if (value === null || value === undefined) {
      return '';
    }
    const groupingSymbol = showGroupingSymbol ? this.userInfo.groupingSymbol : '';
    return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
      .format(value)
      .replace(/[\.,]/g, char => {
        return char === '.' ? this.userInfo.decimalSymbol : groupingSymbol;
      })
      .replace('$', '');
  }

  public formatPercent(value: number, showGroupingSymbol = true): string {
    if (value === null || value === undefined) {
      return '';
    }
    return this.format(value, showGroupingSymbol) + ' %';
  }

  public parse(value: string): number {
    if (!value) {
      return null;
    }
    if (!this.isNumberValid(value)) {
      return Number.NaN;
    }

    const negative = (value.indexOf('-') >= 0);
    const newValue = this.cleanUpValue(value);

    let newNumber = Number(newValue);
    if (negative) {
      newNumber = newNumber * -1;
    }

    return newNumber;
  }

  public validator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (Number.isNaN(control.value)) {
        return {numberFormat: {value: control.value}};
      }
    };
  }

  private isNumberValid(value: string): boolean {
    if (!value) {
      return false;
    }

    const newValue = this.cleanUpValue(value);
    if (newValue.length === 0) {
      return false;
    }

    const newNumber = Number(newValue);
    return !(Number.isNaN(newNumber) || !Number.isFinite(newNumber));
  }

  private cleanUpValue(value: string): string {
    return value.split(this.userInfo.groupingSymbol)
      .join('')
      .replace(/\s/g, '')
      .replace(/^-/, '').replace(/-$/, '')
      .replace(this.userInfo.decimalSymbol, '.');
  }
}
