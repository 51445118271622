import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {PqmErrorService} from '../services/error/error.service';
import {of} from 'rxjs';
import {CommentsActions} from '../actions/comments.action';
import {CollaborationService} from '../services/collaboration.service';

@Injectable()
export class CommentsEffect {

  constructor(private allActions$: Actions, private errorService: PqmErrorService,
              private collaborationService: CollaborationService) {
  }

  fetchComments$ = createEffect(() => this.allActions$.pipe(
    ofType(CommentsActions.fetchComments),
    mergeMap(({itemContainerId}) => this.collaborationService.fetchComments(itemContainerId).pipe(
      map(res => CommentsActions.fetchCommentsSuccess({response: res})),
      catchError((error) => {
        this.errorService.apiError('Failed to fetch comments', error, {itemContainerId}, 'Failed to fetch comments');
        return of(CommentsActions.fetchCommentsFailed());
      })
    ))
  ));

  saveComment$ = createEffect(() => this.allActions$.pipe(
    ofType(CommentsActions.saveComment),
    mergeMap(({itemContainerId, comment}) => this.collaborationService.saveComment(itemContainerId, comment).pipe(
      map(() => CommentsActions.saveCommentSuccess({itemContainerId})),
      catchError((error) => {
        this.errorService.apiError('Failed to save comment', error, {itemContainerId, comment}, 'Failed to save comment');
        return of(CommentsActions.saveCommentFailed());
      })
    ))
  ));

  saveCommentSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(CommentsActions.saveCommentSuccess),
    map(({itemContainerId}) => CommentsActions.fetchComments({itemContainerId}))));

  deleteComment$ = createEffect(() => this.allActions$.pipe(
    ofType(CommentsActions.deleteComment),
    mergeMap(({itemContainerId, id}) => this.collaborationService.deleteComment(id).pipe(
      map(() => CommentsActions.deleteCommentSuccess({itemContainerId})),
      catchError((error) => {
        this.errorService.apiError('Failed to delete comment', error, {itemContainerId, id}, 'Failed to delete comment');
        return of(CommentsActions.deleteCommentFailed());
      })
    ))
  ));

  deleteCommentSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(CommentsActions.deleteCommentSuccess),
    map(({itemContainerId}) => CommentsActions.fetchComments({itemContainerId}))));

  editComment$ = createEffect(() => this.allActions$.pipe(
    ofType(CommentsActions.editComment),
    mergeMap(({itemContainerId, id, comment}) => this.collaborationService.editComment(id, comment).pipe(
      map(() => CommentsActions.editCommentSuccess({itemContainerId, id})),
      catchError((error) => {
        this.errorService.apiError('Failed to edit comment', error, {itemContainerId, id, comment}, 'Failed to edit comment');
        return of(CommentsActions.editCommentFailed());
      })
    ))
  ));

  editCommentSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(CommentsActions.editCommentSuccess),
    map(({itemContainerId}) => CommentsActions.fetchComments({itemContainerId}))));
}
