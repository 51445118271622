import {Component, OnInit} from '@angular/core';
import {AbstractFieldComponent} from './abstract-field.component';
import {DateTime} from 'luxon';
import {Store} from '@ngrx/store';
import {FormGroupDirective} from '@angular/forms';
import {getUserInfo, State} from '../../reducers';
import {DateAdapter} from '@angular/material/core';
import {selectFirstTruthy} from '../../util/rx-utils';
import {PqmDateAdapter} from '../../services/date-adapter.service';
import {UserInfo} from '../../models/UserInfo';

@Component({
  selector: 'pqm-date-field',
  templateUrl: './date-field.component.html',
  providers: [{provide: DateAdapter, useClass: PqmDateAdapter}]
})
export class DateFieldComponent extends AbstractFieldComponent implements OnInit {
  userInfo: UserInfo;

  constructor(store: Store<State>, formDirective: FormGroupDirective, private dateAdapter: DateAdapter<DateTime>) {
    super(store, formDirective);
  }

  ngOnInit(): void {
    selectFirstTruthy(this.store, getUserInfo).subscribe(userInfo => {
      this.userInfo = userInfo;
      if (this.isEditing) {
        const correctedDateFormat = this.getCorrectedDateFormat(userInfo);
        (this.dateAdapter as PqmDateAdapter).setFormat(correctedDateFormat);
        this.dateAdapter.setLocale(userInfo.languageCode);
        this.setupControl();
      }
    });
  }
  dateFilter: (date: DateTime | null) => boolean =
    (date: DateTime | null) => {
    if( this.field !== undefined){
      if ( date !== undefined && date !== null && this.field.minDate >0 && this.field.maxDate >0 ){
        const day = date.ts;
        return day >= this.field.minDate  && day <= this.field.maxDate ;
      }
    }
      return true;

    }
  // override
  protected getFieldValue(): any {
    if (this.field.value) {
      return DateTime.fromMillis(this.field.value, {zone: 'utc', locale: this.userInfo.languageCode});
    } else {
      return null;
    }
  }

  // override
  protected convertValueForServer(value: DateTime): any {
    return value ? value.toMillis() : null;
  }

  private getCorrectedDateFormat(userInfo: UserInfo): string {
    if (userInfo.languageCode.startsWith('zh')) {
      // replace MMM or MMMM with LLLL, so the month is in simplified chinese characters
      return userInfo.dateFormat.replace(/MMM+/, 'LLLL');
    }
    return userInfo.dateFormat;
  }
}
