import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, filter, map, mergeMap} from 'rxjs/operators';
import {PqmErrorService} from '../services/error/error.service';
import {of} from 'rxjs';
import {CollaborationService} from '../services/collaboration.service';
import {ShareWithUserActions} from '../actions/share-with-user.action';
import {OverviewActions} from '../actions/overview.action';

@Injectable()
export class ShareWithUserEffect {

  constructor(private allActions$: Actions, private errorService: PqmErrorService,
              private collaborationService: CollaborationService) {
  }

  changeSalesField$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.changeSalesField),
    concatMap(({field, value}) => this.collaborationService.submitSalesFieldChange(field, value).pipe(
      map(res => ShareWithUserActions.changeSalesFieldSuccess({field, response: res})),
      catchError(error => {
        this.errorService.apiError('Failed to submit field change', error, {field, value}, 'Failed to submit field change');
        return of(ShareWithUserActions.changeSalesFieldFailed());
      })
    ))
  ));

  changeSalesFieldSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.changeSalesFieldSuccess),
    map(res => OverviewActions.fetchOverviewSuccess({response: res.response}))));

  saveSalesFields$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.saveSalesFields),
    concatMap(res => this.collaborationService.saveSalesFields().pipe(
      map(() => ShareWithUserActions.saveSalesFieldsSuccess(res)),
      catchError(error => {
        this.errorService.apiError('Failed to save sales fields', error, {}, 'Failed to save sales fields');
        return of(ShareWithUserActions.saveSalesFieldsFailed());
      })
    ))
  ));

  resetSalesFields$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.resetSalesFields),
    mergeMap(() => {
      return this.collaborationService.resetSalesFields().pipe(
        map(res => ShareWithUserActions.resetSalesFieldsSuccess({response: res})),
        catchError((error) => {
          this.errorService.apiError('Failed to reset sales fields changes', error, {}, 'Failed to reset sales fields changes');
          return of(ShareWithUserActions.resetSalesFieldsFailed());
        })
      );
    })
  ));

  resetSalesFieldsSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.resetSalesFieldsSuccess),
    map(res => OverviewActions.fetchOverviewSuccess({response: res.response}))));

  saveSalesFieldsSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.saveSalesFieldsSuccess),
    filter(({salesFieldsChanges}) => !!salesFieldsChanges.length),
    map(({itemContainerId}) => ShareWithUserActions.getUsersAndTeams({itemContainerId}))));

  saveSalesFieldsSuccess2$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.saveSalesFieldsSuccess),
    map(({itemContainerId}) => ShareWithUserActions.getShares({itemContainerId}))));

  getUsersAndTeams$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.getUsersAndTeams),
    mergeMap(({itemContainerId}) => this.collaborationService.getUsersAndTeams(itemContainerId).pipe(
      map(res => ShareWithUserActions.getUsersAndTeamsSuccess({response: res})),
      catchError((error) => {
        this.errorService.apiError('Failed to fetch users and teams', error, {itemContainerId}, 'Failed to fetch users and teams');
        return of(ShareWithUserActions.getUsersAndTeamsFailed());
      })
    ))
  ));

  share$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.share),
    mergeMap(({itemContainerId, model}) => this.collaborationService.shareWithUsersAndTeams(itemContainerId, model).pipe(
      map(() => ShareWithUserActions.shareSuccess({itemContainerId})),
      catchError((error) => {
        this.errorService.apiError('Failed to share with users and teams', error,
          {itemContainerId, model}, 'Failed to share with users and teams');
        return of(ShareWithUserActions.shareFailed());
      })
    ))
  ));

  shareSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.shareSuccess),
    map(({itemContainerId}) => ShareWithUserActions.getShares({itemContainerId}))));

  getShares$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.getShares),
    mergeMap(({itemContainerId}) => this.collaborationService.getShares(itemContainerId).pipe(
      map(res => ShareWithUserActions.getSharesSuccess({response: res})),
      catchError((error) => {
        this.errorService.apiError('Failed to fetch shares', error, {}, 'Failed to fetch shares');
        return of(ShareWithUserActions.getSharesFailed());
      })
    ))
  ));

  editShare$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.editShare),
    mergeMap(({itemContainerId, model}) => this.collaborationService.editShare(itemContainerId, model).pipe(
      map(() => ShareWithUserActions.editShareSuccess({itemContainerId})),
      catchError((error) => {
        this.errorService.apiError('Failed to edit share', error, {itemContainerId, model}, 'Failed to edit share');
        return of(ShareWithUserActions.editShareFailed());
      })
    ))
  ));

  editShareSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.editShareSuccess),
    map(({itemContainerId}) => ShareWithUserActions.getShares({itemContainerId}))));

  deleteShare$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.deleteShare),
    mergeMap(({itemContainerId, model}) => this.collaborationService.deleteShare(itemContainerId, model).pipe(
      map(() => ShareWithUserActions.deleteShareSuccess({itemContainerId})),
      catchError((error) => {
        this.errorService.apiError('Failed to delete share', error, {itemContainerId, model}, 'Failed to delete share');
        return of(ShareWithUserActions.deleteShareFailed());
      })
    ))
  ));

  deleteShareSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(ShareWithUserActions.deleteShareSuccess),
    map(({itemContainerId}) => ShareWithUserActions.getShares({itemContainerId}))));
}
