import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {PqmErrorService} from './error.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class PqmUncaughtErrorsService implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(err: Error): void {
    const errorService = this.injector.get(PqmErrorService);
    if (err instanceof HttpErrorResponse) {
      errorService.apiError(err.message, err);
    } else {
      errorService.error(err.message, err);
    }
  }
}
