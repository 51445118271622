<h2 mat-dialog-title test-id="NEW_CUSTOMER_DIALOG_TITLE">{{'newCustomer' | pqmLabel}}</h2>

<mat-dialog-content [formGroup]="form">

  <pqm-field-label [label]="'customerName' | pqmLabel" test-id="CUSTOMER_NAME_FIELD_LABEL" [mandatory]="true" [isEditing]="pqmParams.editing"></pqm-field-label>
  <mat-form-field test-id="CUSTOMER_NAME_FIELD">
    <input type="text" matInput id="customerName" formControlName="customerName"/>
    <mat-error *ngIf="customerName.errors?.required">{{'errorMandatoryField' | pqmLabel}}</mat-error>
  </mat-form-field>

  <pqm-field-label [label]="'location' | pqmLabel" test-id="LOCATION_FIELD_LABEL" [isEditing]="pqmParams.editing"></pqm-field-label>
  <mat-form-field test-id="LOCATION_FIELD">
    <input type="text" matInput id="location" formControlName="location">
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="flex-grid flex-shrink m-b-half">
    <button type="button" class="c-button" test-id="SAVE_BUTTON" (click)="save()">{{'save' | pqmLabel}}</button>
    <button type="button" class="c-button c-button--grey-light" test-id="CANCEL_BUTTON" (click)="close()">{{'cancel' | pqmLabel}}</button>
  </div>
</mat-dialog-actions>
