import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {DeleteConfirmComponent} from '../../dialog/delete-confirm.component';
import {MatDialog} from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'pqm-edit-permissions-dropdown',
  templateUrl: './edit-permissions-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }

      .edit-permissions__button {
        white-space: nowrap;
      }
    `
  ]
})
export class EditPermissionsDropdownComponent {

  @Input() viewOnly = true;
  @Output() viewOnlyChange = new EventEmitter<boolean>();
  @Input() readonlyMode = false;
  @Input() hasRemove = true;
  @Output() remove = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {
  }

  onPermissionsChange(viewOnly: boolean): void {
    // skip 'this.viewOnly = viewOnly' and let the parent component update the property
    this.viewOnlyChange.emit(viewOnly);
  }

  onRemove(removeItem: HTMLLIElement): void {
    const removeItemPosition = removeItem.getBoundingClientRect();
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      disableClose: true,
      position: {
        top: (removeItemPosition.top - 150) + 'px'
      },
      data: {
        message: 'removeAccess'
      },
      id: 'DIALOG_CONFIRM_REMOVE_ACCESS'
    });
    dialogRef.afterClosed().subscribe((yes) => {
      if (yes) {
        this.remove.emit();
      }
    });
  }
}
