<ng-container *ngIf="isEditable(); else viewMode">
  <mat-form-field>
    <textarea matInput [formControl]="control" placeholder="{{field.placeholder}}"></textarea>
    <mat-error *ngIf="hasErrors()">{{getErrors()}}</mat-error>
  </mat-form-field>
</ng-container>

<ng-template #viewMode>
  <div class="c-overview-details-grid__value">{{field.value}}</div>
</ng-template>
