<ng-container *ngIf="isEditable(); else viewMode">
  <mat-form-field>
    <div class="c-input-group m-b-none">
      <input type="text" matInput [matAutocomplete]="auto" #typeAheadField [formControl]="control"
             (keyup.escape)="resetDefault()" (blur)="onBlur()">
      <fa-icon icon="caret-down" class="down-arrow"></fa-icon>
    </div>
    <mat-error *ngIf="hasErrors()">{{getErrors()}}</mat-error>
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (opened)="onOpen()"
                    (optionSelected)="valueSelected($event)">
    <mat-option *ngIf="!hasOptions()" class="is-loading" [disabled]="true">{{'noAvailableOptions' | pqmLabel}}</mat-option>
    <ng-container *ngIf="hasOptions()">
      <mat-option *ngFor="let opt of filteredOptions" [value]="opt">
        <div class="dropdown-option">
          <div>{{opt.description}}</div>
          <div *ngIf="opt.email" class="dropdown-option__email">{{opt.email}}</div>
          <div *ngIf="opt.subInfo" class="dropdown-option__sub-info">{{opt.subInfo}}</div>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</ng-container>

<ng-template #viewMode>
  <div class="c-overview-details-grid__value">{{field.selectedOption?.description}}</div>
</ng-template>
