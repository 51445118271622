import {createReducer, on} from '@ngrx/store';
import {CommentsActions} from '../actions/comments.action';
import {Comment} from '../models/Comment';

export interface CommentsState {
  comments: Comment[];
  fetchInProgress?: boolean;
  saveInProgress?: boolean;
  saveSuccess?: boolean;
  editSuccess?: number;
}

const initialState: CommentsState = {
  comments: null
};

export const commentsReducer = createReducer(
  initialState,
  on(CommentsActions.fetchComments, (state) => {
    return {...state, fetchInProgress: true};
  }),
  on(CommentsActions.fetchCommentsSuccess, (state, {response}) => ({
    ...state,
    comments: response,
    fetchInProgress: false,
  })),
  on(CommentsActions.fetchCommentsFailed, (state) => ({...state, fetchInProgress: false})),
  on(CommentsActions.saveComment, (state) => ({
    ...state,
    saveInProgress: true,
    saveSuccess: null
  })),
  on(CommentsActions.saveCommentSuccess, (state) => ({
    ...state,
    saveInProgress: false,
    saveSuccess: true
  })),
  on(CommentsActions.saveCommentFailed, (state) => ({
    ...state,
    saveInProgress: false,
    saveSuccess: false
  })),
  on(CommentsActions.editComment, (state) => ({
    ...state,
    editSuccess: null
  })),
  on(CommentsActions.editCommentSuccess, (state, {id}) => ({
    ...state,
    editSuccess: id
  })),
  on(CommentsActions.resetState, () => initialState),
);

export class CommentsReducers {
  static getComments = (state: CommentsState) => state.comments;
  static getFetchInProgress = (state: CommentsState) => state.fetchInProgress;
  static getSaveInProgress = (state: CommentsState) => state.saveInProgress;
  static getSaveSuccess = (state: CommentsState) => state.saveSuccess;
  static getEditSuccess = (state: CommentsState) => state.editSuccess;
}
