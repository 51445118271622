<ng-container *ngIf="(isSubField || field.type === 'field') && field.visible !== false">
  <pqm-field-label *ngIf="!field.hiddenLabel" [label]="field.description" [mandatory]="field.mandatory" [isSubField]="isSubField"
                   [isEditing]="isEditing"></pqm-field-label>
  <ng-container [ngSwitch]="fieldType">
    <ng-container *ngSwitchCase="fieldTypes.TEXT">
      <pqm-text-field [field]="field" [isEditing]="isEditing" (valueChanged)="valueChanged.emit($event)"></pqm-text-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.CUSTOMER">
      <pqm-customer-field [field]="field" [isEditing]="isEditing" (valueChanged)="valueChanged.emit($event)"></pqm-customer-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.TEXT_AREA">
      <pqm-textarea-field [field]="field" [isEditing]="isEditing" (valueChanged)="valueChanged.emit($event)"></pqm-textarea-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.DROPDOWN">
      <pqm-dropdown-field [field]="field" [isEditing]="isEditing" (valueChanged)="valueChanged.emit($event)"></pqm-dropdown-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.DATE">
      <pqm-date-field [field]="field" [isEditing]="isEditing" (valueChanged)="valueChanged.emit($event)"></pqm-date-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.NUMERIC">
      <pqm-numeric-field [field]="field" [isEditing]="isEditing" (valueChanged)="valueChanged.emit($event)"></pqm-numeric-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.TEXT_COPY">
      <pqm-text-copy-field [field]="field" [isEditing]="isEditing" (valueChanged)="valueChanged.emit($event)"></pqm-text-copy-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.MULTIPICK">
      <pqm-multi-pick-field [field]="field" [isEditing]="isEditing" (valueChanged)="valueChanged.emit($event)"></pqm-multi-pick-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.ANTICIPATED_ORDER_VALUE">
      <pqm-anticipated-order-value-field [field]="field" [isEditing]="isEditing"
                                         (valueChanged)="valueChanged.emit($event)"></pqm-anticipated-order-value-field>
    </ng-container>
    <ng-container *ngSwitchCase="fieldTypes.CONTAINER">
      <div *ngFor="let subField of field.fieldList; trackBy:trackByName">
        <pqm-field [field]="subField" [isSubField]="true" [isEditing]="isEditing" attr.test-id="{{subField.name}}"
                   (valueChanged)="valueChanged.emit($event)"></pqm-field>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
