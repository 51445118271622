export enum FieldTypes {
  ANTICIPATED_ORDER_VALUE = 'ANTICIPATED_ORDER_VALUE',
  CONTAINER = 'CONTAINER',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  NUMERIC = 'NUMERIC',
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  TEXT_COPY = 'TEXT_COPY',
  CUSTOMER = 'CUSTOMER',
  MULTIPICK = 'MULTIPICK',
  CHIP_LIST = 'CHIP_LIST',
}
