<form [formGroup]="form" class="c-collaboration-form p-h-2" [class.cursor-in-progress]="shareInProgress$ | async">
  <div style="display: flex">
    <pqm-chip-list-field #usersAndTeams [field]="usersAndTeamsField" class="m-b-half invite__users-field"
                         attr.test-id="{{usersAndTeamsField.name}}">
    </pqm-chip-list-field>
    <div class="m-l-2">
      <!-- empty label to align field with button -->
      <div class="c-overview-details-grid__label" [style.visibility]="'hidden'">&nbsp;</div>
      <pqm-edit-permissions-dropdown [viewOnly]="form.get('viewOnly').value"
                                     (viewOnlyChange)="form.get('viewOnly').setValue($event)"
                                     [hasRemove]="false"></pqm-edit-permissions-dropdown>
    </div>
  </div>
  <pqm-field [field]="messageField" class="m-b-half" style="display: block" attr.test-id="{{messageField.name}}"></pqm-field>
  <button class="c-button m-t-base m-b-2" type="button" test-id="SHARE_BUTTON" (click)="onShare()">
    <fa-icon icon="share"></fa-icon>
    {{'share' | pqmLabel}}
  </button>
</form>
