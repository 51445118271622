<ng-container *ngIf="isEditable(); else viewMode">
  <mat-form-field>
    <div class="c-input-group m-b-none">
      <input type="text" matInput [matAutocomplete]="auto" #typeAheadField [formControl]="control"
             (focus)="typeAheadField.select()" (keyup.escape)="resetDefault()" (blur)="onBlur()"
             placeholder="{{'searchForCustomer' | pqmLabel}}...">
      <button class="c-button c-button--secondary" type="button" title="{{'customerDetails' | pqmLabel}}" test-id="CUSTOMER_DETAILS_BUTTON"
              *ngIf="field.selectedOption.persistenceId !== '0'" [matMenuTriggerFor]="list" (click)="$event.stopPropagation()">
        <fa-icon icon="info"></fa-icon>
      </button>
      <button class="c-button" type="button" test-id="NEW_CUSTOMER_BUTTON" (click)="$event.stopPropagation(); createNewOption()"
              title="{{'newCustomer' | pqmLabel}}">
        <fa-icon icon="user-plus"></fa-icon>
      </button>
    </div>
    <mat-error *ngIf="hasErrors()">{{getErrors()}}</mat-error>
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption
                    (optionSelected)="valueSelected($event)">
    <ng-container *ngIf="showQuickSelect()">
      <mat-option *ngFor="let opt of quickSelect" [value]="opt">
        {{opt.description}} - {{opt.location}}
      </mat-option>
    </ng-container>
    <mat-option *ngFor="let opt of filteredOptions" [value]="opt">
      {{opt.description}} - {{opt.location}}
    </mat-option>
  </mat-autocomplete>
</ng-container>


<ng-template #viewMode>
  <a *ngIf="field.selectedOption?.persistenceId !== '0'" class="c-overview-details-grid__value"
     [matMenuTriggerFor]="list">
    {{field.selectedOption.description}}
  </a>
</ng-template>

<mat-menu #list="matMenu">
  <ng-template matMenuContent>
    <pqm-customer-details [id]="field.selectedOption?.persistenceId" [customer]="field.selectedOption"
                          (click)="$event.stopPropagation()"></pqm-customer-details>
  </ng-template>
</mat-menu>
