<div class="p-half">
  <p *ngIf="!isEditing" class="comment-section__readonly-comment" test-id="READONLY_COMMENT">{{section.comment}}</p>
  <form *ngIf="isEditing" [formGroup]="form" class="m-b-half">
    <pqm-field attr.test-id="{{field.name}}" [field]="field"></pqm-field>
  </form>
  <div class="comment-section__action-container">
    <p class="comment-section__time text-subdued m-b-none" test-id="COMMENT_TIME">{{section.timeFromNow}}</p>
    <ng-container *ngIf="isOwner">
      <button *ngIf="!isEditing" class="comment-section__edit-button c-button c-button--empty m-l-half p-h-half p-v-none" type="button"
              test-id="COMMENT_EDIT_BUTTON" (click)="editComment()">{{'edit' | pqmLabel}}
      </button>
      <button *ngIf="!isEditing" #deleteButton class="comment-section__delete-button c-button c-button--empty p-h-half p-v-none"
              test-id="COMMENT_DELETE_BUTTON" type="button" (click)="deleteComment()">
        {{'delete' | pqmLabel}}
      </button>
      <button *ngIf="isEditing" class="comment-section__save-button c-button c-button--empty m-l-half p-h-half p-v-none" type="button"
              test-id="COMMENT_SAVE_BUTTON" (click)="saveComment()">{{'save' | pqmLabel}}
      </button>
      <button *ngIf="isEditing" class="comment-section__cancel-button c-button c-button--empty p-h-half p-v-none" type="button"
              test-id="COMMENT_CANCEL_BUTTON" (click)="cancelEditing()">{{'cancel'| pqmLabel}}</button>
    </ng-container>
  </div>
</div>
