import {Component, Input, OnInit} from '@angular/core';
import {Section} from '../models/Section';
import {ExpandedService} from '../services/expanded.service';

@Component({
  selector: 'pqm-section',
  templateUrl: './section.component.html'
})
export class SectionComponent implements OnInit {
  @Input() section: Section;
  expanded: boolean;

  constructor(private expandedService: ExpandedService) {
  }

  ngOnInit(): void {
    this.expanded = this.expandedService.getExpandedState(this.section);
  }

  onToggle(event: Event): void {
    const element = event.target as HTMLElement;
    this.expandedService.toggleExpandedState(this.section, element.hasAttribute('open'));
  }
}
