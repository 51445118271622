import {Injectable} from '@angular/core';
import * as Rollbar from 'rollbar';
import {UserInfo} from '../../models/UserInfo';
import {ROLLBAR_CONFIG} from './rollbar-config';
import {Store} from '@ngrx/store';
import {selectFirstTruthy} from '../../util/rx-utils';
import {getLabels, getUserInfo} from '../../reducers';
import {environment} from '../../../environments/environment';
import {EMPTY, Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PqmErrorService extends Rollbar {
  constructor(private store: Store<any>, private snackbar: MatSnackBar) {
    super(ROLLBAR_CONFIG);
    selectFirstTruthy(store, getUserInfo).subscribe((userInfo: UserInfo) => {
      this.configureUserDetails(userInfo);
    });
  }

  public apiError(message: string, error: HttpErrorResponse, data?: any, userMessage?: string): Observable<any> {
    if (error.status && error.status !== 401) { // don't report timeout
      if (userMessage) {
        this.showSnackbarError(userMessage);
      }
      this.error('HttpError: ' + message, error, data);
    }
    return EMPTY;
  }

  public error(message: string, error: Error, data = {}): any {
    if (environment.rollbar.enabled) {
      super.error(message, {payload: data}, error);
    } else {
      console.error(message, data, error);
    }
  }

  public showSnackbarError(messageOrKey: string): void {
    selectFirstTruthy(this.store, getLabels).subscribe(labels => {
      this.snackbar.open(labels[messageOrKey] || messageOrKey, labels.closeButtonText, {
        panelClass: 'c-global-message',
        verticalPosition: 'top'
      });
    });
  }

  public showSnackbarWarning(messageOrKey: string): void {
    selectFirstTruthy(this.store, getLabels).subscribe(labels => {
      this.snackbar.open(labels[messageOrKey] || messageOrKey, labels.closeButtonText, {
        duration: 3000,
        verticalPosition: 'top'
      });
    });
  }

  private configureUserDetails(userInfo: UserInfo): void {
    super.configure({
      payload: {
        environment: userInfo.serverName || 'unknown',
        person: {
          id: userInfo.userName || 'unknown',
          email: userInfo.email || ''
        }
      }
    });
  }
}
