import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {PqmErrorService} from '../services/error/error.service';
import {of} from 'rxjs';
import {OverviewActions} from '../actions/overview.action';
import {ExpandedService} from '../services/expanded.service';
import {SharedService} from '../services/shared.service';
import {SharedActions} from '../actions/shared.action';

@Injectable()
export class SharedEffect {

  constructor(private allActions$: Actions, private sharedService: SharedService, private errorService: PqmErrorService,
              private expandedService: ExpandedService) {
  }

  initialize$ = createEffect(() => this.allActions$.pipe(
    ofType(SharedActions.initialize),
    mergeMap(() => {
      this.expandedService.initializeState();
      return this.sharedService.initialize().pipe(
        map(res => SharedActions.initializeSuccess({response: res})),
        catchError((error) => {
          this.errorService.apiError('Failed to fetch item container page', error, {}, 'Failed to fetch item container page');
          return of(SharedActions.initializeFailed());
        })
      );
    })
  ));

  initializeSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(SharedActions.initializeSuccess),
    map(({response: {qmConfig, currency, layout, batchConfigure, numberOfItems}}) => OverviewActions.fetchOverviewSuccess({
      response: {qmConfig, currency, layout, batchConfigure, numberOfItems}
    }))));

  initializeFailed$ = createEffect(() => this.allActions$.pipe(
    ofType(SharedActions.initializeFailed),
    map(() => OverviewActions.fetchOverviewFailed())));

  refresh$ = createEffect(() => this.allActions$.pipe(
    ofType(SharedActions.refresh),
    mergeMap(() => {
      return this.sharedService.refresh().pipe(
        map(res => SharedActions.refreshSuccess({response: res})),
        catchError((error) => {
          this.errorService.apiError('Failed to refresh PqmDetails', error, {}, 'Failed to refresh PqmDetails');
          return of(SharedActions.refreshFailed());
        })
      );
    })
  ));

  refreshSuccess$ = createEffect(() => this.allActions$.pipe(
    ofType(SharedActions.refreshSuccess),
    map(res => OverviewActions.fetchOverviewSuccess({response: res.response}))));
}
