export enum FieldNames {
  // @formatter:off
  CustomerName = 'Fld_CustomerName',
  AnticipatedOrderValue = 'Fld_AnticipatedOrderValue',
  AnticipatedOrderValueBehavior = 'Fld_AnticipatedOrderValueBehavior',
  Probability = 'Fld_Probability',
  approvalTotal = 'Fld_QuoteApprovalTotal',

  // contact fields
  PhoneNo = 'Fld_PhoneNo',
  Email = 'Fld_Email',
  MobileNo = 'Fld_MobileNo',
  FaxNo = 'Fld_FaxNo',

  ShipToCustomerName = 'Fld_ShipToCustomerName',
  // @formatter:on
}
