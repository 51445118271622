import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PqmParams} from '../constants/PqmParams';
import {Overview} from '../models/Overview';
import {Comment} from '../models/Comment';
import {Customer} from '../models/Customer';
import {UsersAndTeams} from '../models/UsersAndTeams';
import {ShareWithUsersAndTeamsModel} from './collaboration.service';
import {PqmDetails} from '../models/PqmDetails';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) {
  }

  initialize(): Observable<PqmDetails> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/pqmDetails`;
    return this.httpClient.get<PqmDetails>(url, {
      withCredentials: true,
      params: {
        editMode: PqmParams.editing
      }
    });
  }

  refresh(): Observable<Overview> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/pqmDetails/reset`;
    return this.httpClient.put<Overview>(url, {}, {withCredentials: true});
  }

  submitFieldChange(params: {
    name: string,
    displayType: string,
    value?: any
  }): Observable<Overview> {
    if (params.value == null) {
      // don't send value if null, because server only accepts string values
      delete params.value;
    }
    const url = `${PqmParams.ifeUrl}/itemmanager/api/pqmDetails/field`;
    return this.httpClient.put<Overview>(url, params, {withCredentials: true});
  }

  fillShiftToFields(): Observable<Overview> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/pqmDetails/shipTo`;
    return this.httpClient.put<Overview>(url, {}, {withCredentials: true});
  }

  saveSalesFields(): Observable<void> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/pqmDetails/sales`;
    return this.httpClient.post<void>(url, {}, {withCredentials: true});
  }

  searchForCustomer(params: {
    query: string,
    type: string
  }): Observable<Customer[]> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/customer`;
    return this.httpClient.get<Customer[]>(url, {
      withCredentials: true,
      params
    });
  }

  createCustomer(params: {
    name: string,
    location: string,
    type: string
  }): Observable<Customer> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/customer`;
    return this.httpClient.post<Customer>(url, params, {withCredentials: true});
  }

  fetchComments(itemContainerId: number): Observable<Comment[]> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/comments/${itemContainerId}`;
    return this.httpClient.get<Comment[]>(url, {withCredentials: true});
  }

  saveComment(params: {
    itemContainerId: number,
    comment: string
  }): Observable<void> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/comments`;
    return this.httpClient.post<void>(url, {itemContainerId: params.itemContainerId, comment: params.comment}, {withCredentials: true});
  }

  deleteComment(id: number): Observable<void> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/comments/${id}`;
    // need this to send Access-Control-Request-Headers to the server and make the delete working
    const headers = new HttpHeaders({'Content-Type': '*/*'});
    return this.httpClient.delete<void>(url, {headers, withCredentials: true});
  }

  editComment(id: number, comment: string): Observable<void> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/comments/${id}`;
    return this.httpClient.put<void>(url, {comment}, {withCredentials: true});
  }

  getUsersAndTeams(itemContainerId: number): Observable<UsersAndTeams> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/availableUsers/${itemContainerId}`;
    return this.httpClient.get<UsersAndTeams>(url, {withCredentials: true});
  }

  shareWithUsersAndTeams(itemContainerId: number, model: ShareWithUsersAndTeamsModel): Observable<any> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/share/${itemContainerId}`;
    return this.httpClient.post<any>(url, model, {withCredentials: true});
  }

  getShares(itemContainerId: number): Observable<UsersAndTeams> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/share/${itemContainerId}`;
    return this.httpClient.get<UsersAndTeams>(url, {withCredentials: true});
  }

  editShare(itemContainerId: number, model: ShareWithUsersAndTeamsModel): Observable<void> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/share/${itemContainerId}`;
    return this.httpClient.put<any>(url, model, {withCredentials: true});
  }

  deleteShare(itemContainerId: number, model: ShareWithUsersAndTeamsModel): Observable<void> {
    const url = `${PqmParams.ifeUrl}/itemmanager/api/collaboration/share/${itemContainerId}`;
    return this.httpClient.delete<any>(url, {withCredentials: true, body: model});
  }
}
