import {Component, OnInit} from '@angular/core';
import {AbstractFieldComponent} from './abstract-field.component';

@Component({
  selector: 'pqm-textarea-field',
  templateUrl: './textarea-field.component.html'
})
export class TextareaFieldComponent extends AbstractFieldComponent implements OnInit {
  ngOnInit(): void {
    if (this.isEditing) {
      this.setupControl();
    }
  }
}
