import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {OverviewService} from '../services/overview.service';
import {MessageService} from '../services/message.service';
import {PqmErrorService} from '../services/error/error.service';
import {of} from 'rxjs';
import {OverviewActions} from '../actions/overview.action';

@Injectable()
export class OverviewEffect {

  constructor(private allActions$: Actions, private overviewService: OverviewService,
              private messageService: MessageService, private errorService: PqmErrorService) {
  }

  fillShiftToFields$ = createEffect(() => this.allActions$.pipe(
    ofType(OverviewActions.fillShiftToFields),
    concatMap(() => this.overviewService.fillShiftToFields().pipe(
      map(res => OverviewActions.fetchOverviewSuccess({response: res})),
      catchError(error => {
        this.errorService.apiError('Failed to fill ShiftTo fields', error, null, 'Failed to fill ShiftTo fields');
        return of(OverviewActions.fillShiftToFieldsFailed());
      })
    ))
  ));

  saveOverview$ = createEffect(() => this.allActions$.pipe(
    ofType(OverviewActions.saveOverview),
    tap(({batchConfigure, newQuotedCurrency, deletePricesheetsInAllQuoteItemsFlag}) => this.messageService.postSave(batchConfigure, newQuotedCurrency, deletePricesheetsInAllQuoteItemsFlag ))
  ), {dispatch: false});

  cancelEdit$ = createEffect(() => this.allActions$.pipe(
    ofType(OverviewActions.cancelOverview),
    tap(() => this.messageService.postCancel())
  ), {dispatch: false});

}
