import {Injectable} from '@angular/core';
import {QmConfig} from '../../models/QmConfig';
import {Field} from '../../models/Field';

@Injectable()
export class ShareWithUserService {

  private salesFieldNames = [
    'Fld_QuotingOffice',
    'Fld_ApplicationEngineer',
    'Fld_SalesRegion',
    'Fld_SalesOffice',
    'Fld_SalesEngineer',
    'Fld_DestinationSalesOffice',
    'Fld_DestinationSalesEngineer'
  ];

  getSalesFields(config: QmConfig): Field[] {
    const fields = [];
    this.salesFieldNames.forEach((name) => {
      const field = config.fields[name];
      if (field) {
        fields.push({...field, type: 'field'});
      }
    });
    return fields;
  }

}
