import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {PqmErrorService} from '../../services/error/error.service';
import {ApiService} from '../../services/api.service';
import {PqmParams} from '../../constants/PqmParams';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pqm-create-customer',
  templateUrl: './create-customer.component.html'
})
export class CreateCustomerComponent implements OnInit {
  form: FormGroup;
  pqmParams = PqmParams;

  constructor(private formBuilder: FormBuilder, private dialogRef: MatDialogRef<CreateCustomerComponent>,
              private apiService: ApiService, private errorService: PqmErrorService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.form = this.formBuilder.group({
      customerName: ['', Validators.required],
      location: ['']
    });
  }

  ngOnInit(): void {
  }

  get customerName(): AbstractControl {
    return this.form.get('customerName');
  }

  get location(): AbstractControl {
    return this.form.get('location');
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const params = {
        name: this.customerName.value,
        location: this.location.value,
        type: this.data.type
      };
      this.apiService.createCustomer(params).pipe(
        untilDestroyed(this),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 409) {
            this.errorService.showSnackbarWarning('companyNameLocationDuplicateMsg');
          } else {
            this.errorService.apiError('Error creating customer', error, params, 'Error creating customer');
          }
          return EMPTY;
        })
      ).subscribe((option) => this.dialogRef.close(option));
    }
  }

}
