import {AbstractControl, FormControl, FormGroupDirective, ValidatorFn} from '@angular/forms';
import {Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Field} from '../../models/Field';
import {Store} from '@ngrx/store';
import {getLabels, State} from '../../reducers';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {selectFirstTruthy} from '../../util/rx-utils';
import {Labels} from '../../models/Labels';
import {distinctUntilChanged} from 'rxjs/operators';

export interface SetupControlParams {
  updateOn?: 'blur' | 'change';
}

@UntilDestroy()
@Component({template: ''})
export abstract class AbstractFieldComponent implements OnChanges {

  @Input() field: Field;
  @Input() isEditing = true;
  @Output() valueChanged = new EventEmitter<{ field: Field; value: any }>();

  @HostBinding('class.field__view-mode') get viewMode(): boolean {
    return !this.isEditable();
  }

  control: FormControl;
  labels: Labels = {};

  constructor(protected store: Store<State>, protected formDirective: FormGroupDirective) {
    selectFirstTruthy(store, getLabels).subscribe(labels => this.labels = labels);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field && changes.field.previousValue) {
      this.updateControl();
    }
  }

  isEditable(): boolean {
    return this.isEditing && this.field.editable;
  }

  hasErrors(): boolean {
    return !!(this.control && this.control.errors && (this.control.dirty || this.control.touched));
  }

  getErrors(): string {
    return Object.keys(this.control.errors).map(type => this.getErrorMessage(type, this.control.errors[type])).join('; ');
  }

  protected setupControl(params?: SetupControlParams): void {
    params = params || {};
    this.control = new FormControl(this.getFieldValue(),
      {validators: this.getValidators(), updateOn: this.field.controlParams?.updateOn || params.updateOn || 'blur'});
    this.formDirective.form.addControl(this.field.name, this.control);
    this.control.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(this.onChange.bind(this));
  }

  protected updateControl(): void {
    if (this.control) {
      const oldValue = this.control.value;
      const newValue = this.getFieldValue();
      if (oldValue != newValue) { // only want to update value if user has not changed the value in the field (before submitting)
        this.control.setValue(this.getFieldValue(), {emitEvent: false});
        this.updateDisplayValue();
      }
    }
  }

  protected updateDisplayValue(): void {
    // nothing
  }

  protected getFieldValue(): any {
    return this.field.value;
  }

  protected getValidators(): ValidatorFn[] {
    return [
      this.baseValidator()
    ];
  }

  protected onChange(value: any): void {
    if (this.control.valid) {
      this.valueChanged.emit({field: this.field, value: this.convertValueForServer(value)});
    }
  }

  protected convertValueForServer(value: any): any {
    return value;
  }

  private getErrorMessage(errorKey: string, params: any): string {
    switch (errorKey) {
      case 'required':
        return this.labels.errorMandatoryField;
      case 'maxlength':
        return this.labels.errorMaxLength + params;
      case 'minvalue':
        return this.labels.errorMinValue + params;
      case 'maxvalue':
        return this.labels.errorMaxValue + params;
      case 'matDatepickerParse':
        return this.labels.invalidDateFormat;
      case 'numberFormat':
        return this.labels.invalidNumberFormat;
      default:
        return this.labels.invalidFieldValue;
    }
  }

  protected baseValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (this.field.mandatory && this.isBlank(control.value)) {
        return {required: true};
      } else if (this.field.maxLength && control.value && control.value.length > this.field.maxLength) {
        return {maxlength: this.field.maxLength};
      } else if (this.field.minValue != null && control.value < this.field.minValue) {
        return {minvalue: this.field.minValue};
      } else if (this.field.maxValue != null && control.value > this.field.maxValue) {
        return {maxvalue: this.field.maxValue};
      }
    };
  }

  protected isBlank(value: string): boolean {
    if (value == null) {
      return true;
    }
    if (typeof value === 'string') {
      return value.trim().length === 0;
    }
    return false;
  }
}
