import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {Observable, Subscription, timer} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {CommentSection} from './comment-section-content.component';
import {
  getComments, getCommentSaveInProgress, getUserInfo, isCommentEditSuccess, isCommentSaveSuccess, State
} from '../../reducers';
import {CommentsActions} from '../../actions/comments.action';
import {Comment} from '../../models/Comment';
import {selectValue} from '../../util/rx-utils';
import {getTimeFromNow} from 'src/app/shared/get-time-from-now';
import {Field} from '../../models/Field';
import {FieldTypes} from '../../constants/FieldTypes';
import {LabelService} from '../../services/label.service';
import {UserService} from '../../services/user.service';

@UntilDestroy()
@Component({
  selector: 'pqm-comments',
  templateUrl: './comments.component.html',
  styles: [`
    .add-comment__form {
      display: flex;
    }

    .add-comment__field {
      flex-grow: 1;
    }
  `]
})
export class CommentsComponent implements OnInit, OnDestroy {
  saveInProgress$: Observable<boolean>;
  saveSuccess$: Observable<boolean>;
  editSuccess$: Observable<number>;
  form: FormGroup = new FormGroup({});
  newCommentField: Field;
  commentSections: CommentSection[];
  comments: Comment[];
  isEditingCollection: { [name: string]: boolean } = {};
  languageCode: string;
  timerSubscription: Subscription;

  @Input() itemContainerId: number;

  constructor(private store: Store<State>, private labelService: LabelService, private userService: UserService) {
    this.saveInProgress$ = this.store.select(getCommentSaveInProgress);
    this.saveSuccess$ = this.store.select(isCommentSaveSuccess);
    this.editSuccess$ = this.store.select(isCommentEditSuccess);
    this.newCommentField = {
      type: 'field',
      name: 'newComment',
      description: null,
      hiddenLabel: true,
      displayType: FieldTypes.TEXT_AREA,
      editable: true,
      mandatory: false,
      visible: true,
      maxLength: 4000,
      controlParams: {
        updateOn: 'change'
      },
      placeholder: this.labelService.translateInstant('addComment')
    };
  }

  ngOnInit(): void {
    this.languageCode = selectValue(this.store, getUserInfo)?.languageCode;

    this.store.select(getComments).pipe(
      untilDestroyed(this)
    ).subscribe((comments) => {
      if (comments) {
        this.onCommentsLoaded(comments);
      }
    });

    this.saveSuccess$.pipe(
      untilDestroyed(this)
    ).subscribe((success) => {
      if (success) {
        this.form.reset({newComment: null});
      }
    });

    this.editSuccess$.pipe(
      untilDestroyed(this)
    ).subscribe((id) => {
      if (id) {
        // stop editing the comment
        this.isEditingCollection[String(id)] = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(CommentsActions.resetState());
  }

  onCommentsLoaded(comments: Comment[]): void {
    this.comments = comments;
    this.commentSections = this.comments.map((comment) => {
      const sectionName = String(comment.id);
      if (!this.isEditingCollection.hasOwnProperty(comment.id)) {
        this.isEditingCollection[sectionName] = false;
      }
      return {
        type: 'section',
        name: sectionName,
        description: this.userService.getFullName(comment.userFirstName, comment.userLastName, comment.userEmail),
        visible: true,
        comment: comment.comment,
        commentId: comment.id,
        timeFromNow: this.getTimeFromNow(comment.dateTime),
        username: comment.username
      };
    });
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.isBrowserSupportRelativeTime()) {
      // refresh timeFromNow every 10 seconds
      this.timerSubscription = timer(10000, 10000).pipe(
        untilDestroyed(this)
      ).subscribe(() => {
        this.commentSections.forEach((section, index) => section.timeFromNow = this.getTimeFromNow(this.comments[index].dateTime));
      });
    }
  }

  addComment(): void {
    if (this.form.invalid) {
      return;
    }
    const comment = this.form.get('newComment').value;
    const saveInProgress = selectValue(this.store, getCommentSaveInProgress);
    if (!comment || saveInProgress) {
      return;
    }
    this.store.dispatch(CommentsActions.saveComment({itemContainerId: this.itemContainerId, comment}));
  }

  deleteComment(id: number): void {
    this.store.dispatch(CommentsActions.deleteComment({itemContainerId: this.itemContainerId, id}));
  }

  editComment({id, comment}): void {
    this.store.dispatch(
      CommentsActions.editComment({itemContainerId: this.itemContainerId, id, comment}));
  }

  trackByName(index: number, item): string {
    return item.name;
  }

  private isBrowserSupportRelativeTime(): boolean {
    return !!Intl?.RelativeTimeFormat && !!Intl.RelativeTimeFormat.prototype.format;
  }

  private getTimeFromNow(dateTime: number): string {
    if (!this.isBrowserSupportRelativeTime() || !this.languageCode) {
      return;
    }
    const timeFromNow = getTimeFromNow(dateTime);
    if (timeFromNow.time === 0) {
      return this.labelService.translateInstant('now');
    } else {
      const rtf = new Intl.RelativeTimeFormat(this.languageCode);
      return rtf.format(timeFromNow.time, timeFromNow.unitOfTime);
    }
  }
}
