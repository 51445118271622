<pqm-global-css (allLoaded)="allCssLoaded()"></pqm-global-css>

<div *ngIf="initError$ | async; else main" class="c-global-message" style="margin: 20px;" test-id="INIT_ERROR_MESSAGE">
  An error occurred initializing the page
</div>

<ng-template #main>
  <div class="main-container" [style]="{visibility: cssLoaded ? 'visible' : 'hidden'}" [attr.data-ui-density]="displayDensity$ | async">
    <ng-container [ngSwitch]="page">
      <pqm-overview-page *ngSwitchCase="'overview'"></pqm-overview-page>
      <pqm-collaboration-page *ngSwitchCase="'collaboration'" [itemContainerId]="itemContainerId"></pqm-collaboration-page>
      <pqm-documents-page *ngSwitchCase="'modernDocuments'" [itemContainerId]="itemContainerId"></pqm-documents-page>
    </ng-container>
  </div>
</ng-template>
