import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {PageNames} from '../constants/PageNames';
import {PqmParams} from '../constants/PqmParams';

@Injectable({providedIn: 'root'})
export class MessageService {
  public pageChange = new Subject<PageNames>();
  public refreshNeeded = new Subject<void>();

  constructor() {
    window.addEventListener('message', event => {
      switch (event.data.message) {
        case 'pqmDetailsPageChange':
          this.pageChange.next(event.data.page);
          break;
        case 'pqmDetailsRefreshNeeded':
          this.refreshNeeded.next();
          break;
      }
    });
  }

  postSave(batchConfigure: boolean, newQuotedCurrency: boolean, deletePricesheetsInAllQuoteItemsFlag: boolean): void {
    window.parent.postMessage({message: 'pqmDetailsSave', batchConfigure, newQuotedCurrency: newQuotedCurrency, deletePricesheetsInAllQuoteItemsFlag: deletePricesheetsInAllQuoteItemsFlag}, PqmParams.ifeUrl);
  }

  postCancel(): void {
    window.parent.postMessage({message: 'pqmDetailsCancel'}, PqmParams.ifeUrl);
  }

  postCustomer(customerId: string): void {
    window.parent.postMessage({message: 'pqmDetailsCustomer', customerId}, PqmParams.ifeUrl);
  }

  requestPage(): void {
    window.parent.postMessage({message: 'pqmDetailsRequestPage'}, PqmParams.ifeUrl);
  }
}
