import {createReducer, on} from '@ngrx/store';
import {Labels} from '../models/Labels';
import {UserInfo} from '../models/UserInfo';
import {SharedActions} from '../actions/shared.action';

export interface SharedState {
  userInfo: UserInfo;
  labels: Labels;
  editPermission: boolean;
  archive: boolean;
  initFailure: boolean;
}

const initialState: SharedState = {
  userInfo: null,
  labels: null,
  editPermission: null,
  archive: null,
  initFailure: null,
};

export const sharedReducer = createReducer(
  initialState,
  on(SharedActions.initializeSuccess, (state, {response: {userInfo, labels, editPermission, archive}}) => ({
    ...state,
    userInfo,
    labels,
    editPermission,
    archive,
    initFailure: false
  })),
  on(SharedActions.initializeFailed, (state) => ({...state, initFailure: true})),
);

export class SharedReducers {
  static getUserInfo = (state: SharedState) => state.userInfo;
  static getLabels = (state: SharedState) => state.labels;
  static getUserEditPermission = (state: SharedState) => state.editPermission;
  static getIsOldRevision = (state: SharedState) => state.archive;
  static getInitFailure = (state: SharedState) => state.initFailure;
}


