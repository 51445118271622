import {createReducer, on} from '@ngrx/store';
import {OverviewActions} from '../actions/overview.action';
import {Overview} from '../models/Overview';

export interface OverviewState extends Overview {
  changeInProgress?: boolean;
  saveInProgress?: boolean;
  initFailure?: boolean;
}

const initialState: OverviewState = {
  qmConfig: null,
  currency: null,
  layout: null,
  batchConfigure: null,
  numberOfItems: 0,
};

export const overviewReducer = createReducer(
  initialState,
  on(OverviewActions.fetchOverviewSuccess, (state, {response}) => ({
    ...state,
    ...response,
    changeInProgress: false
  })),
  on(OverviewActions.fetchOverviewFailed, (state) => ({...state, initFailure: true})),
  on(OverviewActions.saveOverview, (state) => ({...state, saveInProgress: true})),
);

export class OverviewReducers {
  static getQmConfig = (state: OverviewState) => state.qmConfig;
  static getCurrency = (state: OverviewState) => state.currency;
  static getLayout = (state: OverviewState) => state.layout;
  static getBatchConfigure = (state: OverviewState) => state.batchConfigure;
  static getNumberOfItems = (state: OverviewState) => state.numberOfItems;
  static getSaveInProgress = (state: OverviewState) => state.saveInProgress;
  static getInitFailure = (state: OverviewState) => state.initFailure;
}


