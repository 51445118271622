<ng-container *ngIf="users?.length || teams?.length">
  <h4 class="p-t-base" test-id="SHARED_WITH_TITLE">{{'sharedWith' | pqmLabel}}</h4>
  <div class="table-overflow p-b-2">
    <table class="shares__table" [class.cursor-in-progress]="isInProgress$ | async">
      <thead>
      <tr>
        <th test-id="NAME_HEADER">{{'name' | pqmLabel}}</th>
        <th test-id="EMAIL_HEADER">{{'emailUppercase' | pqmLabel}}</th>
        <th test-id="PERMISSIONS_HEADER" class="text-center"></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let user of users; trackBy: trackById">
        <ng-container *ngTemplateOutlet="shareItem; context: {share: user, type: 'user'}"></ng-container>
      </ng-container>
      <ng-container *ngFor="let team of teams; trackBy: trackById">
        <ng-container *ngTemplateOutlet="shareItem; context: {share: team, type: 'team'}"></ng-container>
      </ng-container>
      <ng-template #shareItem let-share="share" let-type="type">
        <tr>
          <td class="shares__name-cell" test-id="NAME_CELL">
            {{share.fullName || share.name}} <em *ngIf="share.translatedRoles">({{share.translatedRoles}})</em>
          </td>
          <td class="shares__email-cell" test-id="EMAIL_CELL">{{share.email}}</td>
          <td class="text-center shares__action-menu-cell" test-id="PERMISSIONS_CELL">
            <pqm-edit-permissions-dropdown [style.visibility]="isActionMenuVisible(share, type) ? 'visible' : 'hidden'"
                                           [viewOnly]="share.viewOnly"
                                           (viewOnlyChange)="editShare({share: share, type: type, viewOnly: $event})"
                                           [readonlyMode]="!isActionMenuVisible(share, type)"
                                           (remove)="deleteShare({share: share, type: type})"></pqm-edit-permissions-dropdown>
          </td>
        </tr>
      </ng-template>
      </tbody>
    </table>
  </div>
</ng-container>
