import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApiService} from './api.service';
import {PqmDetails} from '../models/PqmDetails';
import {Overview} from '../models/Overview';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private apiService: ApiService) {
  }

  initialize(): Observable<PqmDetails> {
    return this.apiService.initialize();
  }

  refresh(): Observable<Overview> {
    return this.apiService.refresh();
  }
}
