<!--need p-h-half class not to cut invalid comment field box-shadow-->
<div *ngIf="isInitialized; else loading" class="flex-grid p-h-half" style="flex-wrap: wrap-reverse;">
  <div>
    <!-- invisible header to align Comments and Shared With section -->
    <h3 class="p-v-half" style="visibility: hidden;">&nbsp;</h3>
    <pqm-comments [itemContainerId]="itemContainerId" [ngClass]="{'comments__restricted-width': !isEditSectionAvailable()}"></pqm-comments>
  </div>
  <div *ngIf="isEditSectionAvailable()">
    <h3 class="p-h-2 p-v-half" test-id="SHARE_WITH_USERS_TITLE">{{'shareWithUsers' | pqmLabel}}</h3>
    <pqm-share-with-user [itemContainerId]="itemContainerId"></pqm-share-with-user>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate" [diameter]="50" style="margin: 20px auto"></mat-progress-spinner>
</ng-template>

