import {Component, OnInit} from '@angular/core';
import {AbstractFieldComponent} from './abstract-field.component';

@Component({
  selector: 'pqm-text-field',
  templateUrl: './text-field.component.html'
})
export class TextFieldComponent extends AbstractFieldComponent implements OnInit {

  ngOnInit(): void {
    if (this.isEditing) {
      this.setupControl();
    }
  }
}
