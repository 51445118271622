import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'pqm-field-label',
  templateUrl: './field-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldLabelComponent {

  @Input() label: string;
  @Input() mandatory: boolean;
  @Input() isSubField = false;
  @Input() isEditing = true;
}
