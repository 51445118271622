<form [formGroup]="form" class="c-collaboration-form p-h-2" [class.cursor-in-progress]="isInProgress$ | async">

  <ng-template #fieldList let-customClass="customClass">
    <div class="sales-fields__container" [class.sales-fields__container--editing]="editMode">
      <pqm-field *ngFor="let field of fields; trackBy: trackByName" [field]="field" [class]="customClass"
                 attr.test-id="{{field.name}}" [isEditing]="editMode" style="display: block;"
                 (valueChanged)="onFieldChanged($event)"></pqm-field>
    </div>
  </ng-template>

  <!-- currently fields do not handle isEditing input changes so use separate list of fields for edit/readonly mode -->
  <ng-container *ngIf="!editMode">
    <ng-container *ngTemplateOutlet="fieldList; context: {customClass: 'm-b-2'}"></ng-container>
  </ng-container>
  <ng-container *ngIf="editMode">
    <ng-container *ngTemplateOutlet="fieldList; context: {customClass: 'm-b-half'}"></ng-container>
  </ng-container>

  <ul class="c-menu c-menu--inline">
    <li *ngIf="!editMode">
      <button class="c-button m-t-base m-b-2 sales-info__edit-button" type="button" test-id="EDIT_BUTTON" (click)="onEdit()">
        <fa-icon icon="pencil-alt" size="sm"></fa-icon>
        {{'edit' | pqmLabel}}
      </button>
    </li>
    <li *ngIf="editMode">
      <button class="c-button m-t-base m-b-2 sales-info__save-button" type="button" test-id="SAVE_BUTTON" (click)="onSave()">
        <fa-icon icon="share"></fa-icon>
        {{'saveAndInvite' | pqmLabel}}
      </button>
    </li>
    <li *ngIf="editMode">
      <button class="c-button c-button--grey-light m-t-base m-b-2 sales-info__cancel-button" type="button" test-id="CANCEL_BUTTON"
              (click)="onCancel()">
        {{'cancel' | pqmLabel}}
      </button>
    </li>
  </ul>
</form>
