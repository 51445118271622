import {Component, OnInit} from '@angular/core';
import {AbstractFieldComponent} from './abstract-field.component';
import {getQmConfig} from '../../reducers';
import {FieldNames} from '../../constants/FieldNames';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CustomerField} from '../../models/Customer';
import {OverviewActions} from '../../actions/overview.action';

@UntilDestroy()
@Component({
  selector: 'pqm-text-copy-field',
  templateUrl: './text-copy-field.component.html'
})
export class TextCopyFieldComponent extends AbstractFieldComponent implements OnInit {
  valueToCopy: any;
  copyButtonLabel: string;

  ngOnInit(): void {
    if (this.isEditing) {
      this.copyButtonLabel = this.getCopyButtonLabel();
      this.setupControl();
      this.store.select(getQmConfig).pipe(untilDestroyed(this)).subscribe(qmConfig => {
        const customerField = qmConfig.fields[FieldNames.CustomerName] as CustomerField;
        const customer = customerField?.selectedOption;
        if (customer) {
          if (this.field.name === FieldNames.PhoneNo) {
            this.valueToCopy = customer.phone;
          } else if (this.field.name === FieldNames.Email) {
            this.valueToCopy = customer.email;
          } else if (this.field.name === FieldNames.MobileNo) {
            this.valueToCopy = customer.mobile;
          } else if (this.field.name === FieldNames.FaxNo) {
            this.valueToCopy = customer.fax;
          } else if (this.field.name === FieldNames.ShipToCustomerName) {
            this.valueToCopy = customer.description;
          }
        }
      });
    }
  }

  getCopyButtonLabel(): string {
    if (this.field.name === FieldNames.ShipToCustomerName) {
      return 'CopyFromBillToCustomer';
    } else {
      return 'copyFromCustomer';
    }
  }

  onCopy(event): void {
    event.stopPropagation();
    if (this.field.name === FieldNames.ShipToCustomerName) {
      this.store.dispatch(OverviewActions.fillShiftToFields());
    } else {
      this.control.setValue(this.valueToCopy);
    }
  }
}
