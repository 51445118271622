import {Pipe, PipeTransform} from '@angular/core';
import {LabelService} from '../services/label.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({name: 'pqmLabel', pure: false})
export class PqmLabelPipe implements PipeTransform {
  value = '';
  lastKey = null;

  constructor(private labelService: LabelService) {
  }

  transform(key: string): string {
    if (key !== this.lastKey) {
      this.lastKey = key;
      this.labelService.get(key).pipe(untilDestroyed(this)).subscribe(value => this.value = value);
    }
    return this.value;
  }
}
