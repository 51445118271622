import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Section} from '../../models/Section';
import {FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {DeleteConfirmComponent} from '../dialog/delete-confirm.component';
import {Field} from '../../models/Field';
import {FieldTypes} from '../../constants/FieldTypes';
import {FieldComponent} from '../field.component';
import {getUserInfo, State} from '../../reducers';
import {selectValue} from '../../util/rx-utils';
import {Store} from '@ngrx/store';

export interface CommentSection extends Section {
  comment: string;
  commentId: number;
  timeFromNow: string;
  username: string;
}

@Component({
  selector: 'pqm-comment-section-content',
  templateUrl: './comment-section-content.component.html',
  styles: [
    `.comment-section__readonly-comment {
      white-space: pre-wrap;
    }

    .comment-section__action-container {
      display: flex;
      align-items: center;
    }

    .comment-section__time {
      font-size: smaller;
    }
    `
  ]
})
export class CommentSectionContentComponent implements OnInit {

  @Input() section: CommentSection;
  @Output() save = new EventEmitter<{ id: number, comment: string }>();
  @Output() delete = new EventEmitter<number>();
  @ViewChild(FieldComponent, {read: ElementRef}) commentField: ElementRef;
  @ViewChild('deleteButton') deleteButton: ElementRef;

  @Input() isEditing = false;
  @Output() isEditingChange = new EventEmitter<boolean>();

  form: FormGroup = new FormGroup({});
  field: Field;
  isOwner = false;

  constructor(private store: Store<State>, private dialog: MatDialog) {
    this.field = {
      type: 'field',
      name: 'comment',
      description: null,
      hiddenLabel: true,
      displayType: FieldTypes.TEXT_AREA,
      editable: true,
      mandatory: true,
      visible: true,
      maxLength: 4000,
      controlParams: {
        updateOn: 'change'
      }
    };
  }

  ngOnInit(): void {
    this.isOwner = this.section.username && this.section.username === selectValue(this.store, getUserInfo)?.userName;
    if (this.isEditing) {
      this.field.value = this.section.comment;
      setTimeout(() => {
        this.adjustCommentFieldHeight();
      });
    }
  }

  editComment(): void {
    this.isEditing = true;
    this.isEditingChange.emit(true);
    // if edit the same comment two and more times the control will keep previous value if won't recreate the form
    this.form = new FormGroup({});
    this.field.value = this.section.comment;
    setTimeout(() => {
      this.adjustCommentFieldHeight();
    });
  }

  deleteComment(): void {
    const deleteButtonPosition = this.deleteButton.nativeElement.getBoundingClientRect();
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      disableClose: true,
      position: {
        top: (deleteButtonPosition.top - 150) + 'px'
      },
      data: {
        message: 'deleteComment'
      },
      id: 'DIALOG_CONFIRM_DELETE_COMMENT'
    });
    dialogRef.afterClosed().subscribe((yes) => {
      if (yes) {
        this.delete.emit(this.section.commentId);
      }
    });
  }

  saveComment(): void {
    if (this.form.invalid) {
      return;
    }
    if (this.form.get('comment').value !== this.section.comment) {
      this.save.emit({id: this.section.commentId, comment: this.form.get('comment').value});
    } else {
      this.isEditing = false;
      this.isEditingChange.emit(false);
    }
  }

  cancelEditing(): void {
    this.isEditing = false;
    this.isEditingChange.emit(false);
  }

  private adjustCommentFieldHeight(): void {
    const element: HTMLTextAreaElement = this.commentField?.nativeElement.getElementsByTagName('textarea')[0];
    if (!element) {
      return;
    }
    const scrollHeight = element.scrollHeight;
    if (element.clientHeight < scrollHeight) {
      element.style.height = scrollHeight + 10 + 'px';
    }
  }
}
