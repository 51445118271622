<ng-container *ngIf="isEditable(); else viewMode">
  <mat-form-field *ngIf="control">
    <div class="c-input-group m-b-none">
      <input type="text" matInput [formControl]="control"  [matDatepickerFilter]="dateFilter" [matDatepicker]="picker"/>
      <button class="c-button c-button--secondary" type="button" test-id="OPEN_DATE_PICKER_BUTTON" (click)="picker.open()">
        <fa-icon icon="calendar"></fa-icon>
      </button>
    </div>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="hasErrors()">{{getErrors()}}</mat-error>
  </mat-form-field>
</ng-container>

<ng-template #viewMode>
  <div class="c-overview-details-grid__value">
    {{field.formattedValue}}
  </div>
</ng-template>

