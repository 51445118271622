import {createAction, props} from '@ngrx/store';
import {Overview} from '../models/Overview';

const prefix = '[overview] ';

export namespace OverviewActions {
  export const fetchOverviewSuccess = createAction(
    prefix + 'Fetch overview success',
    props<{ response: Overview }>()
  );

  export const fetchOverviewFailed = createAction(
    prefix + 'Fetch overview failed'
  );

  export const changeFieldFailed = createAction(
    prefix + 'Change field failed'
  );

  export const fillShiftToFields = createAction(
    prefix + 'Fill ShiftTo fields'
  );

  export const fillShiftToFieldsFailed = createAction(
    prefix + 'Fill ShiftTo fields failed'
  );

  export const saveOverview = createAction(
    prefix + 'Save overview',
    props<{ batchConfigure: boolean, newQuotedCurrency: boolean, deletePricesheetsInAllQuoteItemsFlag: boolean }>()
  );

  export const cancelOverview = createAction(
    prefix + 'Cancel overview'
  );
}
