<div class="details-name text-center m-b-base" test-id="CUSTOMER_DETAILS_NAME">{{customer.description}}</div>
<ng-container *ngFor="let item of items">
  <div *ngIf="item[1]" class="details-item" attr.test-id="{{item[0]}}">
    <div>{{item[0] | pqmLabel}}</div>
    <div class="m-l-2 text-right">{{item[1]}}</div>
  </div>
</ng-container>
<div *ngIf="!isEditing" class="m-t-base text-center">
  <button class="c-text-button" test-id="GO_TO_CUSTOMER_BUTTON" (click)="goToCustomer()">
    <fa-icon icon="external-link-alt" class="m-r-base"></fa-icon>
    <span>{{'customerDetails' | pqmLabel}}</span>
  </button>
</div>
