import {Component, OnInit} from '@angular/core';
import {AbstractFieldComponent} from './abstract-field.component';
import {Store} from '@ngrx/store';
import {FormGroupDirective, ValidatorFn} from '@angular/forms';
import {getCurrency, getQmConfig, State} from '../../reducers';
import {NumberFormatService} from '../../services/number-format.service';
import {FieldNames} from '../../constants/FieldNames';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pqm-anticipated-order-value-field',
  templateUrl: './anticipated-order-value-field.component.html',
  styles: [
    `
      .currency-symbol {
        white-space: nowrap;
        padding-right: calc(0.25em * var(--ui-density));
        padding-top: calc(0.5em * var(--ui-density));
      }
    `
  ]
})
export class AnticipatedOrderValueFieldComponent extends AbstractFieldComponent implements OnInit {
  displayValue = '';
  currencySymbol: string;
  behaviorField;
  autoCalculate = false;

  constructor(store: Store<State>, formDirective: FormGroupDirective, private numberFormatService: NumberFormatService) {
    super(store, formDirective);
  }

  ngOnInit(): void {
    this.updateDisplayValue();
    this.store.select(getQmConfig).pipe(untilDestroyed(this)).subscribe(qmConfig => {
      this.behaviorField = qmConfig.fields[FieldNames.AnticipatedOrderValueBehavior];
      this.autoCalculate = this.behaviorField && this.behaviorField.selectedOption?.persistenceId !== '0';
      if (this.isEditing) {
        this.setupControl();
      }
    });

    this.store.select(getCurrency).pipe(untilDestroyed(this)).subscribe(currency => {
      this.currencySymbol = currency.display;
    });
  }

  // override
  protected updateDisplayValue(): void {
    this.displayValue = this.numberFormatService.formatCurrency(this.field.value);
  }

  // override
  isEditable(): boolean {
    return super.isEditable() && !this.autoCalculate;
  }

  // override
  protected getValidators(): ValidatorFn[] {
    const validators = super.getValidators();
    validators.push(this.numberFormatService.validator());
    return validators;
  }
}
