import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {FormControl, FormGroup} from '@angular/forms';
import {UsersAndTeams} from '../../../models/UsersAndTeams';
import {ChipListField, Field} from '../../../models/Field';
import {getShareInProgress, getShareSuccess, getUsersAndTeams, State} from '../../../reducers';
import {UserService} from '../../../services/user.service';
import {FieldTypes} from '../../../constants/FieldTypes';
import {TeamChipListOption, UserChipListOption} from '../../../models/Option';
import {ShareWithUsersAndTeamsModel} from '../../../services/collaboration.service';
import {Observable} from 'rxjs';
import {LabelService} from '../../../services/label.service';
import {selectValue} from '../../../util/rx-utils';
import {ChipListFieldComponent} from '../../fields/chip-list-field.component';

@UntilDestroy()
@Component({
  selector: 'pqm-invite-form',
  templateUrl: './invite-form.component.html',
  styles: [
    `
      .invite__users-field {
        display: block;
        flex-grow: 1;
      }
    `
  ]
})
export class InviteFormComponent implements OnInit {

  @Output() share = new EventEmitter<ShareWithUsersAndTeamsModel>();
  @ViewChild('usersAndTeams') usersAndTeamsFieldComponent: ChipListFieldComponent;

  usersAndTeams: UsersAndTeams;
  form: FormGroup = new FormGroup({viewOnly: new FormControl()});
  usersAndTeamsField: ChipListField;
  messageField: Field;
  shareSuccess$: Observable<boolean>;
  shareInProgress$: Observable<boolean>;

  constructor(private store: Store<State>, private userService: UserService, private labelService: LabelService) {
    this.usersAndTeamsField = {
      type: 'field',
      name: 'usersAndTeams',
      description: this.labelService.translateInstant('inviteUserOrTeam'),
      displayType: FieldTypes.CHIP_LIST,
      editable: true,
      mandatory: false,
      visible: true,
      optionList: []
    };
    this.messageField = {
      type: 'field',
      name: 'message',
      description: this.labelService.translateInstant('includeMessage'),
      displayType: FieldTypes.TEXT_AREA,
      editable: true,
      mandatory: false,
      visible: true,
      maxLength: 4000
    };
    this.shareSuccess$ = this.store.select(getShareSuccess);
    this.shareInProgress$ = this.store.select(getShareInProgress);
  }

  ngOnInit(): void {
    this.store.select(getUsersAndTeams).pipe(
      untilDestroyed(this)
    ).subscribe((usersAndTeams) => {
      if (usersAndTeams) {
        this.form.reset({...this.form.value, usersAndTeams: []});
        this.onDataLoaded(usersAndTeams);
      }
    });
    this.shareSuccess$.pipe(
      untilDestroyed(this)
    ).subscribe((success) => {
      if (success) {
        this.form.reset({...this.form.value, usersAndTeams: [], message: null});
      }
    });
  }

  onDataLoaded(usersAndTeams: UsersAndTeams): void {
    this.usersAndTeams = usersAndTeams;
    const userOptions: UserChipListOption[] = this.usersAndTeams.users.map((user) => {
      return {
        type: 'user',
        description: this.userService.getFullName(user.firstName, user.lastName),
        email: user.email,
        persistenceId: String(user.id),
        chipDisplayValue: [user.firstName, user.lastName].join(' ').trim() || user.name
      };
    });
    const teamOptions: TeamChipListOption[] = this.usersAndTeams.teams.map((team) => {
      return {
        type: 'team',
        description: team.name,
        subInfo: this.labelService.translateInstant('team'),
        persistenceId: String(team.id),
        chipDisplayValue: team.name
      };
    });
    this.usersAndTeamsField.optionList = [...userOptions, ...teamOptions];
    this.usersAndTeamsFieldComponent?.onOptionsUpdated();
  }

  onShare(): void {
    if (this.form.invalid) {
      return;
    }
    const saveInProgress = selectValue(this.store, getShareInProgress);
    if (saveInProgress) {
      return;
    }
    const usersAndTeams: (UserChipListOption | TeamChipListOption)[] = this.form.get('usersAndTeams').value;
    if (!usersAndTeams?.length) {
      return;
    }
    const users = this.usersAndTeams.users.filter((user) => {
      return !!usersAndTeams.find(item => (item.type === 'user') && Number(item.persistenceId) === user.id);
    });
    const teams = this.usersAndTeams.teams.filter((team) => {
      return !!usersAndTeams.find(item => (item.type === 'team') && Number(item.persistenceId) === team.id);
    });
    const viewOnly = this.form.get('viewOnly').value;
    const model: ShareWithUsersAndTeamsModel = {
      users: users.map((user) => ({
        id: user.id, viewOnly: viewOnly ?? false
      })),
      teams: teams.map((team) => ({
        id: team.id, viewOnly: viewOnly ?? false
      }))
    };
    const message = this.form.get('message').value;
    if (message) {
      model.message = message;
    }
    this.share.emit(model);
  }
}
