import {createAction, props} from '@ngrx/store';
import {PqmDetails} from '../models/PqmDetails';
import {Overview} from '../models/Overview';

const prefix = '[shared] ';

export namespace SharedActions {
  export const initialize = createAction(
    prefix + 'Initialize PqmDetails'
  );

  export const initializeSuccess = createAction(
    prefix + 'Initialize PqmDetails success',
    props<{ response: PqmDetails }>()
  );

  export const initializeFailed = createAction(
    prefix + 'Initialize PqmDetails failed'
  );

  export const refresh = createAction(
    prefix + 'Refresh PqmDetails'
  );

  export const refreshSuccess = createAction(
    prefix + 'Refresh PqmDetails success',
    props<{ response: Overview }>()
  );

  export const refreshFailed = createAction(
    prefix + 'Refresh PqmDetails failed'
  );
}
