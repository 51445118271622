import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Section} from '../../models/Section';
import {getQmConfig, getSalesFieldChangeSuccess, getSalesFieldsResetSuccess, getSalesFieldsSaveSuccess, State} from '../../reducers';
import {Store} from '@ngrx/store';
import {ShareWithUserActions} from '../../actions/share-with-user.action';
import {ShareWithUsersAndTeamsModel} from '../../services/collaboration.service';
import {LabelService} from '../../services/label.service';
import {Field} from '../../models/Field';
import {ShareWithUserService} from './share-with-user.service';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'pqm-share-with-user',
  templateUrl: './share-with-user.component.html',
  providers: [ShareWithUserService],
  styles: [
    `
      .c-details-sharing {
        border-radius: 3px;
      }
    `
  ]
})
export class ShareWithUserComponent implements OnInit, OnDestroy {

  @Input() itemContainerId: number;

  salesInfoSection: Section;
  inviteSection: Section;
  salesFields$: Observable<Field[]>;
  salesFieldsChanges: string[] = [];

  constructor(private store: Store<State>, private labelService: LabelService, private service: ShareWithUserService) {
    this.salesInfoSection = {
      type: 'section',
      name: 'salesInfoSection',
      description: this.labelService.translateInstant('salesInformation'),
      visible: true,
      expanded: false
    };
    this.inviteSection = {
      type: 'section',
      name: 'inviteEditorsSection',
      description: this.labelService.translateInstant('inviteOthers'),
      visible: true,
      expanded: false
    };
  }

  ngOnInit(): void {
    this.store.dispatch(ShareWithUserActions.getUsersAndTeams({itemContainerId: this.itemContainerId}));

    this.salesFields$ = this.store.select(getQmConfig)
      .pipe(filter(qmConfig => !!qmConfig), map(qmConfig => this.service.getSalesFields(qmConfig)));

    this.store.select(getSalesFieldChangeSuccess).pipe(untilDestroyed(this)).subscribe(res => {
      if (typeof res === 'string') { // the field changed successfully, save its name
        this.salesFieldsChanges.push(res);
      }
    });

    this.store.select(getSalesFieldsResetSuccess).pipe(untilDestroyed(this)).subscribe(success => {
      if (success) {
        this.salesFieldsChanges = [];
      }
    });

    this.store.select(getSalesFieldsSaveSuccess).pipe(untilDestroyed(this)).subscribe(success => {
      if (success) {
        this.salesFieldsChanges = [];
      }
    });
  }

  changeSalesField(data: { field: Field, value: any }): void {
    this.store.dispatch(ShareWithUserActions.changeSalesField(data));
  }

  saveSalesFields(): void {
    this.store.dispatch(
      ShareWithUserActions.saveSalesFields({itemContainerId: this.itemContainerId, salesFieldsChanges: this.salesFieldsChanges}));
  }

  resetSalesFields({hasChanges}): void {
    if (hasChanges) {
      this.store.dispatch(ShareWithUserActions.resetSalesFields());
    }
  }

  share(model: ShareWithUsersAndTeamsModel): void {
    this.store.dispatch(ShareWithUserActions.share({itemContainerId: this.itemContainerId, model}));
  }

  edit(model: ShareWithUsersAndTeamsModel): void {
    this.store.dispatch(ShareWithUserActions.editShare({itemContainerId: this.itemContainerId, model}));
  }

  delete(model: ShareWithUsersAndTeamsModel): void {
    this.store.dispatch(ShareWithUserActions.deleteShare({itemContainerId: this.itemContainerId, model}));
  }

  ngOnDestroy(): void {
    this.store.dispatch(ShareWithUserActions.resetState());
  }
}
