import {Injectable} from '@angular/core';
import {createSelector, Store} from '@ngrx/store';
import {getBatchConfigure, getCurrency, getNumberOfItems, getOverviewState, getQmConfig, State} from '../reducers';
import {BatchConfigure} from '../models/BatchConfigure';
import {SaveConfirmComponent} from '../components/dialog/save-confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {OverviewService} from './overview.service';
import {OverviewActions} from '../actions/overview.action';
import {selectValue} from '../util/rx-utils';
import {OverviewReducers} from '../reducers/overview.reducer';
import {PqmParams} from '../constants/PqmParams';
import {AlertDialogComponent} from '../components/dialog/alert-dialog/alert-dialog.component';

interface SaveResult {
  save: boolean;
  batchConfigure: boolean;
  newQuotedCurrency: boolean;
  deletePricesheetsInAllQuoteItemsFlag: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SaveService {

  constructor(private store: Store<State>, private dialog: MatDialog, private overviewService: OverviewService) {
  }

  async save(): Promise<void> {
    const saveLoading = document.getElementsByClassName('hideWaiting');
    if (saveLoading.length > 0){
      document.getElementById('waitingToSave').classList.remove('hideWaiting');
      document.getElementById('waitingToSave').classList.add('showWaiting');
    }
    const batchConfigure = selectValue(this.store, getBatchConfigure);
    const numberOfItems = selectValue(this.store, getNumberOfItems);
    const result = await this.confirmBatchConfigure(batchConfigure, numberOfItems);
    const resultValidations = await this.validateCurrency(numberOfItems);

    if (result.save ) {
      let newQuotedCurrencyValue = false;
      let deletePricesheetsInAllQuoteItemsFlagValue = false;
      try{
        newQuotedCurrencyValue = resultValidations.newQuotedCurrency;
        deletePricesheetsInAllQuoteItemsFlagValue =  resultValidations.deletePricesheetsInAllQuoteItemsFlag;
      }catch (error){
        console.log(error);
      }
      if (resultValidations === undefined || resultValidations.save !== undefined ){
        this.store.dispatch(OverviewActions.saveOverview(
          {
            batchConfigure: result.batchConfigure, newQuotedCurrency: newQuotedCurrencyValue ,
            deletePricesheetsInAllQuoteItemsFlag: deletePricesheetsInAllQuoteItemsFlagValue
          }));
      }else{
       document.getElementById('waitingToSave').classList.remove('showWaiting');
       document.getElementById('waitingToSave').classList.add('hideWaiting');
     }
    }
  }


  private async validateCurrency(numberOfItems): Promise<SaveResult> {
    const message: Array<string> = [];
    let discountoption: boolean;
    let itemCurrency: boolean;

    if (this.overviewService.isFieldChanged('Fld_QuotedCurrencyCode')) {
      message.push('quotedCurrencyChangeAlertMsg');
      itemCurrency = true;
    }
    if (this.overviewService.isFieldChanged('Fld_Discounts')) {
      message.push('discountCodeChangeAlertMsg');
      discountoption = true;
    }
    if (message.length > 0 && numberOfItems > 0 && PqmParams.itemContainerId > 0) {
      const result = await this.promptCurrencyChange(message);
      return {
        save: result,
        batchConfigure: false,
        newQuotedCurrency: result && itemCurrency,
        deletePricesheetsInAllQuoteItemsFlag: result  && discountoption
      };
    }
  }

  private async confirmBatchConfigure(batchConfigure: BatchConfigure, numberOfItems: number): Promise<SaveResult> {

    if (numberOfItems > 0 && batchConfigure && PqmParams.itemContainerId > 0) {
      if (batchConfigure.onFieldChange &&
        !!batchConfigure.onFieldChange.find(fieldName => this.overviewService.isFieldChanged(fieldName))) {
        return {
          save: await this.promptForBatchConfigure('batchConfigureOnFieldChange'),
          batchConfigure: true,
          newQuotedCurrency: false,
          deletePricesheetsInAllQuoteItemsFlag: false
        };
      } else if (batchConfigure.promptOnQuoteSave) {
        return {
          save: true,
          batchConfigure: await this.promptForBatchConfigure('batchConfigurePromptOnQuoteSave'),
          newQuotedCurrency: false,
          deletePricesheetsInAllQuoteItemsFlag: false
        };
      }
    }
    return {save: true, batchConfigure: false, newQuotedCurrency: false,
      deletePricesheetsInAllQuoteItemsFlag: false};
  }

  private promptForBatchConfigure(messageKey: string): Promise<boolean> {
    const dialogRef = this.dialog.open(SaveConfirmComponent, {
      disableClose: true,
      position: {
        top: '10px'
      },
      data: {
        message: messageKey
      },
      id: 'DIALOG_CONFIRM_SAVE'
    });
    return new Promise(resolve => {
      dialogRef.afterClosed().subscribe((yes) => {
        resolve(yes);
      });
    });
  }

  private promptCurrencyChange(messageKey: string[]): Promise<boolean> {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      position: {
        top: '10px'
      },
      data: {
        message: messageKey
      },
      id: 'DIALOG_MESSAGE'
    });
    return new Promise(resolve => {
      dialogRef.afterClosed().subscribe((yes) => {
        resolve(yes);
      });
    });
  }

}
