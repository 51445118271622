import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApiService} from './api.service';
import {Comment} from '../models/Comment';
import {UsersAndTeams} from '../models/UsersAndTeams';
import {Overview} from '../models/Overview';
import {Field} from '../models/Field';

export interface ShareWithUsersAndTeamsModel {
  users?: { id: number, viewOnly?: boolean }[];
  teams?: { id: number, viewOnly?: boolean }[];
  message?: string;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class CollaborationService {

  constructor(private apiService: ApiService) {
  }

  fetchComments(itemContainerId: number): Observable<Comment[]> {
    return this.apiService.fetchComments(itemContainerId);
  }

  saveComment(itemContainerId: number, comment: string): Observable<void> {
    return this.apiService.saveComment({itemContainerId, comment});
  }

  deleteComment(id: number): Observable<void> {
    return this.apiService.deleteComment(id);
  }

  editComment(id: number, comment: string): Observable<void> {
    return this.apiService.editComment(id, comment);
  }

  submitSalesFieldChange(field: Field, newValue: any): Observable<Overview> {
    return this.apiService.submitFieldChange({
      name: field.name,
      displayType: field.displayType,
      value: newValue
    });
  }

  saveSalesFields(): Observable<void> {
    return this.apiService.saveSalesFields();
  }

  resetSalesFields(): Observable<Overview> {
    return this.apiService.refresh();
  }

  getUsersAndTeams(itemContainerId: number): Observable<UsersAndTeams> {
    return this.apiService.getUsersAndTeams(itemContainerId);
  }

  shareWithUsersAndTeams(itemContainerId: number, model: ShareWithUsersAndTeamsModel): Observable<any> {
    return this.apiService.shareWithUsersAndTeams(itemContainerId, model);
  }

  getShares(itemContainerId: number): Observable<UsersAndTeams> {
    return this.apiService.getShares(itemContainerId);
  }

  editShare(itemContainerId: number, model: ShareWithUsersAndTeamsModel): Observable<void> {
    return this.apiService.editShare(itemContainerId, model);
  }

  deleteShare(itemContainerId: number, model: ShareWithUsersAndTeamsModel): Observable<void> {
    return this.apiService.deleteShare(itemContainerId, model);
  }
}
