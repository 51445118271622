import {Injectable} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {DateTime, Info} from 'luxon';

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

/*
 * Used https://github.com/andreialecu/ngx-material-luxon as a starting point.
 */
@Injectable()
export class PqmDateAdapter extends DateAdapter<DateTime> {

  formatString: string;

  setFormat(format: string): void {
    this.formatString = format;
  }

  getYear(date: DateTime): number {
    return date.year;
  }

  getMonth(date: DateTime): number {
    return date.month - 1;
  }

  getDate(date: DateTime): number {
    return date.day;
  }

  getDayOfWeek(date: DateTime): number {
    return date.weekday;
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return Info.months(style, {locale: this.locale});
  }

  getDateNames(): string[] {
    const dtf = new Intl.DateTimeFormat(this.locale, {
      day: 'numeric',
      timeZone: 'utc',
    });

    return range(31, (i) => {
      // Format a UTC date in order to avoid DST issues.
      const date = DateTime.utc(2017, 1, i + 1).toJSDate();

      // Strip the directionality characters from the formatted date.
      return dtf.format(date).replace(/[\u200e\u200f]/g, '');
    });
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const luxonWeekdays = [...Info.weekdays(style, {locale: this.locale})];
    luxonWeekdays.unshift(luxonWeekdays.pop());
    return luxonWeekdays;
  }

  getYearName(date: DateTime): string {
    return date.toFormat('yyyy');
  }

  getFirstDayOfWeek(): number {
    return 0;
  }

  getNumDaysInMonth(date: DateTime): number {
    return date.daysInMonth;
  }

  clone(date: DateTime): DateTime {
    return DateTime.fromObject(date.toObject({includeConfig: true}));
  }

  createDate(year: number, month: number, date: number): DateTime {
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const result = DateTime.utc(year, month + 1, date);
    if (!this.isValid(result)) {
      throw Error(`Invalid date "${date}". Reason: "${result.invalidReason}".`);
    }
    return result.setLocale(this.locale);
  }

  today(): DateTime {
    return DateTime.utc().setLocale(this.locale);
  }

  parse(value: any, parseFormat: string | string[]): DateTime | null {
    if (typeof value === 'string' && value.length > 0) {
      const fromFormat = DateTime.fromFormat(value, this.formatString, {zone: 'utc', locale: this.locale});
      if (this.isValid(fromFormat)) {
        return fromFormat;
      } else {
        return this.invalid();
      }
    }
    return null;
  }

  format(date: DateTime): string {
    if (!this.isValid(date)) {
      throw Error('Cannot format invalid date.');
    }
    return date.toFormat(this.formatString, {zone: 'utc', locale: this.locale});
  }

  addCalendarYears(date: DateTime, years: number): DateTime {
    return date.plus({years}).setLocale(this.locale);
  }

  addCalendarMonths(date: DateTime, months: number): DateTime {
    return date.plus({months}).setLocale(this.locale);
  }

  addCalendarDays(date: DateTime, days: number): DateTime {
    return date.plus({days}).setLocale(this.locale);
  }

  toIso8601(date: DateTime): string {
    return date.toISO();
  }

  isDateInstance(obj: any): boolean {
    return obj instanceof DateTime;
  }

  isValid(date: DateTime): boolean {
    return date.isValid;
  }

  invalid(): DateTime {
    return DateTime.invalid('Invalid Date');
  }
}
