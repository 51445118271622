import {ActionReducerMap, createSelector} from '@ngrx/store';
import {overviewReducer, OverviewReducers, OverviewState} from './overview.reducer';
import {commentsReducer, CommentsReducers, CommentsState} from './comments.reducer';
import {shareWithUserReducer, ShareWithUserReducers, ShareWithUserState} from './share-with-user.reducer';
import {sharedReducer, SharedReducers, SharedState} from './shared.reducer';

export interface State {
  shared: SharedState;
  overview: OverviewState;
  comments: CommentsState;
  shareWithUser: ShareWithUserState;
}

export const reducers: ActionReducerMap<State> = {
  shared: sharedReducer,
  overview: overviewReducer,
  comments: commentsReducer,
  shareWithUser: shareWithUserReducer,
};

export const getSharedState = (state: State) => state.shared;
export const isInitFailure = createSelector(getSharedState, SharedReducers.getInitFailure);
export const getLabels = createSelector(getSharedState, SharedReducers.getLabels);
export const getUserInfo = createSelector(getSharedState, SharedReducers.getUserInfo);
export const getUserEditPermission = createSelector(getSharedState, SharedReducers.getUserEditPermission);
export const getIsOldRevision = createSelector(getSharedState, SharedReducers.getIsOldRevision);

export const getOverviewState = (state: State) => state.overview;
export const getQmConfig = createSelector(getOverviewState, OverviewReducers.getQmConfig);
export const getCurrency = createSelector(getOverviewState, OverviewReducers.getCurrency);
export const getLayout = createSelector(getOverviewState, OverviewReducers.getLayout);
export const getBatchConfigure = createSelector(getOverviewState,
  OverviewReducers.getBatchConfigure);
export const getNumberOfItems = createSelector(getOverviewState,
  OverviewReducers.getNumberOfItems);
export const getSaveInProgress = createSelector(getOverviewState, OverviewReducers.getSaveInProgress);
export const isOverviewInitFailure = createSelector(getOverviewState, OverviewReducers.getInitFailure);

export const getCommentsState = (state: State) => state.comments;
export const getComments = createSelector(getCommentsState, CommentsReducers.getComments);
export const getCommentsFetchInProgress = createSelector(getCommentsState, CommentsReducers.getFetchInProgress);
export const getCommentSaveInProgress = createSelector(getCommentsState, CommentsReducers.getSaveInProgress);
export const isCommentSaveSuccess = createSelector(getCommentsState, CommentsReducers.getSaveSuccess);
export const isCommentEditSuccess = createSelector(getCommentsState, CommentsReducers.getEditSuccess);

export const getShareWithUserState = (state: State) => state.shareWithUser;
export const getSalesFieldChangeInProgress = createSelector(getShareWithUserState, ShareWithUserReducers.getSalesFieldChangeInProgress);
export const getSalesFieldChangeSuccess = createSelector(getShareWithUserState, ShareWithUserReducers.getSalesFieldChangeSuccess);
export const getSalesFieldsSaveInProgress = createSelector(getShareWithUserState, ShareWithUserReducers.getSalesFieldsSaveInProgress);
export const getSalesFieldsSaveSuccess = createSelector(getShareWithUserState, ShareWithUserReducers.getSalesFieldsSaveSuccess);
export const getSalesFieldsResetInProgress = createSelector(getShareWithUserState, ShareWithUserReducers.getSalesFieldsResetInProgress);
export const getSalesFieldsResetSuccess = createSelector(getShareWithUserState, ShareWithUserReducers.getSalesFieldsResetSuccess);
export const getUsersAndTeams = createSelector(getShareWithUserState, ShareWithUserReducers.getUsersAndTeams);
export const getShareInProgress = createSelector(getShareWithUserState, ShareWithUserReducers.getShareInProgress);
export const getShareSuccess = createSelector(getShareWithUserState, ShareWithUserReducers.getShareSuccess);
export const fetchSharesInProgress = createSelector(getShareWithUserState, ShareWithUserReducers.fetchSharesInProgress);
export const getEditShareInProgress = createSelector(getShareWithUserState, ShareWithUserReducers.getEditShareInProgress);
export const getEditShareSuccess = createSelector(getShareWithUserState, ShareWithUserReducers.getEditShareSuccess);
export const getDeleteShareInProgress = createSelector(getShareWithUserState, ShareWithUserReducers.getDeleteShareInProgress);
export const getDeleteShareSuccess = createSelector(getShareWithUserState, ShareWithUserReducers.getDeleteShareSuccess);
export const getShares = createSelector(getShareWithUserState, ShareWithUserReducers.getShares);


