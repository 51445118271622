import {
  createAction,
  props
} from '@ngrx/store';
import {UsersAndTeams} from '../models/UsersAndTeams';
import {ShareWithUsersAndTeamsModel} from '../services/collaboration.service';
import {Field} from '../models/Field';
import {Overview} from '../models/Overview';

const prefix = '[share-with-user] ';

export namespace ShareWithUserActions {
  export const changeSalesField = createAction(prefix + 'Change sales field', props<{ field: Field, value: any }>());

  export const changeSalesFieldSuccess = createAction(prefix + 'Change sales field success', props<{ field: Field, response: Overview }>());

  export const changeSalesFieldFailed = createAction(prefix + 'Change sales field failed');

  export const saveSalesFields = createAction(prefix + 'Save sales fields',
    props<{ itemContainerId: number, salesFieldsChanges: string[] }>());

  export const saveSalesFieldsSuccess = createAction(prefix + 'Save sales fields success',
    props<{ itemContainerId: number, salesFieldsChanges: string[] }>());

  export const saveSalesFieldsFailed = createAction(prefix + 'Save sales fields failed');

  export const resetSalesFields = createAction(prefix + 'Reset sales fields');

  export const resetSalesFieldsSuccess = createAction(prefix + 'Reset sales fields success', props<{ response: Overview }>());

  export const resetSalesFieldsFailed = createAction(prefix + 'Reset sales fields failed');

  export const getUsersAndTeams = createAction(prefix + 'Get users and teams', props<{ itemContainerId: number }>());

  export const getUsersAndTeamsSuccess = createAction(prefix + 'Get users and teams success', props<{ response: UsersAndTeams }>());

  export const getUsersAndTeamsFailed = createAction(prefix + 'Get users and teams failed');

  export const share = createAction(prefix + 'Share with users and teams',
    props<{ itemContainerId: number, model: ShareWithUsersAndTeamsModel }>());

  export const shareSuccess = createAction(prefix + 'Share with users and teams success', props<{ itemContainerId: number }>());

  export const shareFailed = createAction(prefix + 'Share with users and teams failed');

  export const getShares = createAction(prefix + 'Get shares', props<{ itemContainerId: number }>());

  export const getSharesSuccess = createAction(prefix + 'Get shares success', props<{ response: UsersAndTeams }>());

  export const getSharesFailed = createAction(prefix + 'Get shares failed');

  export const editShare = createAction(prefix + 'Edit share', props<{ itemContainerId: number, model: ShareWithUsersAndTeamsModel }>());

  export const editShareSuccess = createAction(prefix + 'Edit share success', props<{ itemContainerId: number }>());

  export const editShareFailed = createAction(prefix + 'Edit share failed');

  export const deleteShare = createAction(prefix + 'Delete share',
    props<{ itemContainerId: number, model: ShareWithUsersAndTeamsModel }>());

  export const deleteShareSuccess = createAction(prefix + 'Delete share success', props<{ itemContainerId: number }>());

  export const deleteShareFailed = createAction(prefix + 'Delete share failed');

  export const resetState = createAction(prefix + 'Reset ShareWithUser state');
}
