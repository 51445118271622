import {UrlUtil} from '../util/url-util';

function stripTrailingSlash(str: string): string {
  return str && str.replace(/\/$/, '');
}

export class PqmParams {
  static page: string;
  static ifeUrl: string;
  static itemContainerId: number;
  static editing: boolean;
  static cssPath: string;

  public static initFromUrl(): void {
    const query = UrlUtil.parseUrlQuery(UrlUtil.getCurrentUrl());
    const orgFolderHome = stripTrailingSlash(query.get('orgFolderHome'));
    const orgUnitFolderHome = stripTrailingSlash(query.get('orgUnitFolderHome'));
    const page = query.get('page');
    const ifeUrl = query.get('ifeUrl');
    const itemContainerId = Number(query.get('itemContainerId'));

    if (orgFolderHome && page && ifeUrl) { // orgUnitFolderHome is optional
      PqmParams.page = page;
      PqmParams.ifeUrl = ifeUrl;
      PqmParams.itemContainerId = itemContainerId;
      PqmParams.editing = this.page.endsWith('edit');
      PqmParams.cssPath = UrlUtil.createPath(this.ifeUrl, 'itemmanager', orgFolderHome, orgUnitFolderHome, 'css');
    } else {
      console.error('PQM Details URL is missing query params: ' + query);
    }
  }
}


