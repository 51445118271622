import {environment} from '../../../environments/environment';
import {UrlUtil} from '../../util/url-util';
import * as Rollbar from 'rollbar';

export const ROLLBAR_CONFIG: Rollbar.Configuration = {
  accessToken: '42962356e2774d9ca8ddcd14f819286f',
  captureUncaught: true,
  captureUnhandledRejections: true,
  verbose: true,
  enabled: environment.rollbar.enabled,
  maxItems: 10,
  payload: {
    client: {
      javascript: {
        code_version: environment.serverEnv,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      }
    }
  },

  // replace domain in sourcemap url with 'dynamichost' to support all domains
  transform: (payload: any) => {
    const trace = payload.body.trace;
    const locRegex = getLocRegex();
    if (trace && trace.frames) {
      for (const frame of trace.frames) {
        const filename = frame.filename;
        if (filename) {
          const m = filename.match(locRegex);
          frame.filename = m[1] + '://dynamichost' + m[2];
        }
      }
    }
  }
};

function getLocRegex(): RegExp {
  const escapedHost = UrlUtil.getHost().replace('.', '\\.');
  return new RegExp('^(https?):\\/\\/' + escapedHost + '(.*)');
}
