import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormGroupDirective, ValidatorFn} from '@angular/forms';
import {Option} from '../../models/Option';
import {Store} from '@ngrx/store';
import {State} from '../../reducers';
import {AbstractFieldComponent} from './abstract-field.component';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
  selector: 'pqm-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styles: [
    `
      input {
        cursor: pointer;
      }

      .down-arrow {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, .6);
      }

      input[aria-expanded="true"] {
        cursor: text;
      }
    `
  ]
})
export class DropdownFieldComponent extends AbstractFieldComponent implements OnInit {
  @ViewChild('typeAheadField') typeaheadInput: ElementRef;
  filteredOptions: Option[];
  backUpOption: Option;

  constructor(store: Store<State>, formDirective: FormGroupDirective) {
    super(store, formDirective);
  }

  ngOnInit(): void {
    if (this.isEditing) {
      this.setupControl({updateOn: 'change'});
      this.backUpOption = this.field.selectedOption;
      this.filterData();
    }
  }

  // override
  protected getFieldValue(): any {
    return this.field.selectedOption;
  }

  // override
  protected getValidators(): ValidatorFn[] {
    return [this.typeaheadSelectionValidator()];
  }

  // override
  protected onChange(value): void {
    if (this.field.optionList && typeof value === 'string') {
      setTimeout(() => { // using timeout for performance (change event finishes before search is performed)
        this.filterData(value);
      });
    }
  }

  // override
  protected updateControl(): void {
    super.updateControl();
    this.backUpOption = this.field.selectedOption;
    this.filterData();
  }

  valueSelected($event: MatAutocompleteSelectedEvent): void {
    const choice = $event.option.value;
    if (this.field.selectedOption?.persistenceId === choice?.persistenceId) {
      // user selected the same value, so don't submit
    } else {
      this.persistChange(choice.persistenceId);
    }
    this.typeaheadInput.nativeElement.blur();
  }

  persistChange(persistenceId: number): void {
    this.valueChanged.emit({field: this.field, value: String(persistenceId)});
  }

  onOpen(): void {
    this.typeaheadInput.nativeElement.select();
  }

  onBlur(): void {
    if (this.control.value === '') {
      if (this.field.optionList.find(item => item.persistenceId === '0')) {
        this.persistChange(0);
      } else {
        this.resetDefault();
      }
    }
  }

  displayFn(val: Option): string {
    return val ? val.description : '';
  }

  resetDefault(): void {
    this.control.setValue(this.backUpOption);
  }

  hasOptions(): boolean {
    return this.field.optionList.some(item => item.persistenceId !== '0');
  }

  private typeaheadSelectionValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selection: any = control.value;
      if (this.field.mandatory && (!selection || selection.persistenceId === '0') && this.hasOptions()) {
        return {required: true};
      } else if (selection && typeof selection === 'string') {
        return {selectionRequired: true};
      }
      return null;
    };
  }

  private filterData(searchValue?: string): void {
    let allFilteredOptions;
    if (searchValue) {
      const lowerCaseValue = searchValue.toLowerCase();
      allFilteredOptions = this.field.optionList.filter(option => option.description?.toLowerCase().indexOf(lowerCaseValue) >= 0);
    } else {
      allFilteredOptions = this.field.optionList;
    }
    this.filteredOptions = allFilteredOptions.slice(0, 500);
  }
}
